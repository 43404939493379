/// moved here for readability. Simply the template for buildling jupyter notebooks with the converter.

export const fetch_code_template = `
import numpy as np
from IPython.display import display, HTML, Image
import requests
import os
import json
import tempfile
import re
import time
import pandas as pd

# Authentication token, replace with your actual token
token = "" # @param {type:"string"}

def download_file(url, filename):
    """
    Downloads a file from the given URL and saves it with the specified filename.
    
    Parameters:
        url (str): The URL of the file to download.
        filename (str): The name to save the downloaded file as.
        
    Returns:
        str: The filename if the download is successful, None otherwise.
    """
    response = requests.get(url)
    if response.status_code == 200:
        with open(filename, 'wb') as f:
            f.write(response.content)
        return filename
    else:
        print(f"Error downloading the file: {response.status_code}")
        return None

def render_3d_viewer(url):
    """
    Renders a 3D model from the given URL using meshplot.
    
    Parameters:
        url (str): The URL of the 3D model to render.
    """
    filename = url.split('/')[-1]
    downloaded_file = download_file(url, filename)
    
    if downloaded_file:
        # Load the mesh using meshplot (or any other suitable library)
        print("View Model")
    else:
        print("Failed to download or render the 3D model.")

def render_text(asset_url):
    """
    Fetches and displays text content from the given URL.
    
    Parameters:
        asset_url (str): The URL of the text asset to display.
    """
    response = requests.get(asset_url)
    if response.status_code == 200:
        text = response.text
        # Replace newline characters with HTML line break tags
        formatted_text = text.replace('\\n', '<br>')
        # Display the formatted text as HTML in the notebook
        display(HTML(f'<div style="word-wrap: break-word;">{formatted_text}</div>'))
    else:
        print(f"Failed to fetch text content: HTTP error! status: {response.status_code}")

def render_json(json_url):
    """
    Fetches and displays JSON content from the given URL.
    
    Parameters:
        json_url (str): The URL of the JSON asset to display.
    """
    response = requests.get(json_url)
    if response.status_code == 200:
        json_data = response.json()
        pretty_json = json.dumps(json_data, indent=4)
        display(HTML(f'<pre>{pretty_json}</pre>'))
    else:
        print("Failed to fetch JSON data")

def render_html(html_url):
    """
    Fetches and displays HTML content from the given URL.
    
    Parameters:
        html_url (str): The URL of the HTML asset to display.
    """
    response = requests.get(html_url)
    if response.status_code == 200:
        display(HTML(response.text))
    else:
        print("Failed to fetch HTML content")

def render_image(image_url):
    """
    Displays an image from the given URL.
    
    Parameters:
        image_url (str): The URL of the image to display.
    """
    display(Image(url=image_url))

def fetch_artifact(model_id, artifact_id):
    """
    Fetches metadata for a specific artifact from the Istari API.
    
    Parameters:
        model_id (str): The ID of the model containing the artifact.
        artifact_id (str): The ID of the artifact to fetch.
        
    Returns:
        dict: A dictionary containing artifact metadata, or an error message.
    """
    
    endpoint = f"https://file-service-eks.dev.istari.app/api/model/{model_id}/artifact/{artifact_id}"
    headers = {"Content-Type": "application/json"}
    headers["Authorization"] = f"Bearer {token}"
    response = requests.get(endpoint, headers=headers)
    if response.status_code == 200:
        artifact_data = response.json()  # Assuming the response is in JSON format

        artifact_info = {
            "link": endpoint,
            "meta": {
                "artifact_name": artifact_data.get("name", "Default Artifact Name"),
                "artifact_id": artifact_id,
                "model_name": "Model Name Placeholder",  # This might need to be fetched separately if not in artifact_data
                "model_id": model_id,
                "last_updated": artifact_data.get("updated_at", "Default Last Updated"),
                "asset_type": artifact_data.get("artifact_extension", "Image"),
                "asset_url": artifact_data.get("signed_download_url", "https://s3.us-east-1.amazonaws.com/istari-file-service-auth-dev/860ea617-a01a-46de-a5e2-272f173b5e33?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAYKVZV47CU4P6Y7II%2F20240402%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240402T145340Z&X-Amz-Expires=30&X-Amz-SignedHeaders=host&X-Amz-Signature=31621e6194988a8dcc7f31962dbc615806208b465251bfdd18b6fb802e01fcdd"),  # Adjust based on actual response structure
                "url": endpoint
            }
        }

        return artifact_info
    else:
        return {"error": f"Failed to retrieve artifact, status code: {response.status_code}"}

def render_asset_based_on_extension(asset_url, asset_extension):
    """
    Renders an asset based on its extension by calling the appropriate render function.
    
    Parameters:
        asset_url (str): The URL of the asset to render.
        asset_extension (str): The file extension of the asset (e.g., 'jpg', 'txt', 'json').
    """
    asset_extension = asset_extension.lower()
    
    if asset_extension in ["jpg", "jpeg", "png", "gif", "svg", "bmp"]:
        render_image(asset_url)
    elif asset_extension == "txt":
        render_text(asset_url)
    elif asset_extension == "json":
        render_json(asset_url)
    elif asset_extension == "html":
        render_html(asset_url)
    elif asset_extension in ["xlsx", "xls", "csv", "workbook", "sheet"]:
        render_sheet(asset_url)
    elif asset_extension == "obj":
        render_3d_viewer(asset_url)
    elif asset_extension == "error":
        render_error(asset_url)
    elif asset_extension == "message":
        render_message(asset_url)
    else:
        print(f"Unsupported asset extension: {asset_extension}")

def render_sheet(sheet_url):
    """
    Fetches and displays spreadsheet content (xlsx, xls, csv) from the given URL using pandas.
    
    Parameters:
        sheet_url (str): The URL of the spreadsheet asset to display.
    """
    response = requests.get(sheet_url)
    if response.status_code == 200:
        with tempfile.NamedTemporaryFile(delete=False, suffix=".xlsx") as temp_file:
            temp_file.write(response.content)
            temp_file_path = temp_file.name
        
        try:
            df = pd.read_excel(temp_file_path)
        except ValueError:
            df = pd.read_csv(temp_file_path)
        
        display(df)
        os.remove(temp_file_path)
    else:
        print("Failed to fetch spreadsheet content")

def render_error(error_url):
    """
    Displays an error message retrieved from the given URL.
    
    Parameters:
        error_url (str): The URL of the error message to display.
    """
    response = requests.get(error_url)
    if response.status_code == 200:
        error_message = response.text
        display(HTML(f'<div style="color: red;">{error_message}</div>'))
    else:
        print(f"Failed to fetch error message: HTTP error! status: {response.status_code}")

def render_message(message_url):
    """
    Displays a general message retrieved from the given URL.
    
    Parameters:
        message_url (str): The URL of the message to display.
    """
    response = requests.get(message_url)
    if response.status_code == 200:
        message = response.text
        display(HTML(f'<div style="word-wrap: break-word;">{message}</div>'))
    else:
        print(f"Failed to fetch message: HTTP error! status: {response.status_code}")
    
`;
