import { Avatar, Button, Stack, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { LogOut, User } from "react-feather";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import { RoutePathType, SettingsSubpage } from "../../../../enums";
import { customColors } from "../../../../lib/utils/colors";
import Modal from "../../../Modal";
import Submenu from "../../../Submenu";

interface UserMenuProps {}
const UserMenu: React.FC<UserMenuProps> = () => {
  const auth = useAuth();
  const { user } = auth;
  const displayName = `${user?.profile.given_name} ${user?.profile.family_name}`;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutConfirmPopupOpen, setLogoutConfirmPopupOpen] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const menuItems = useMemo(
    () => ({
      profile: {
        label: t("header.userMenu.profile"),
        icon: User,
        name: "profile-settings",
      },
      logout: {
        label: t("header.userMenu.logout"),
        icon: LogOut,
        name: "logout",
      },
    }),
    [t],
  );

  const logoutCancelHandler = useCallback(() => {
    setLogoutConfirmPopupOpen(false);
  }, []);

  const logoutConfirmHandler = useCallback(async () => {
    try {
      setIsLeaving(true);
      auth.removeUser();
      auth.signoutRedirect();
    } catch (error) {
      setIsLeaving(false);
    }
    setIsLeaving(false);
  }, [auth]);

  const onClickHandler = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    // OPEN DROPDOWN MENU
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenuHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    // ClOSE DROPDOWN MENU
    setAnchorEl(null);
  }, []);

  const menuItemClickHandler = useCallback(
    (event: React.MouseEvent<HTMLElement>, itemLabel: string) => {
      event.stopPropagation();

      switch (itemLabel) {
        case menuItems.logout.name: {
          setLogoutConfirmPopupOpen(true);
          break;
        }
        case menuItems.profile.name: {
          navigate({
            pathname: `${RoutePathType.Root}${RoutePathType.Settings}`,
            search: `?tab=${SettingsSubpage.PROFILE_SETTINGS}`,
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    [menuItems, navigate],
  );

  const logoutContent = <Typography variant="subtitle3">Are you sure you want to log out?</Typography>;

  return (
    <>
      <Button
        data-testid="header-user-profile-menu-container"
        disableRipple
        onClick={onClickHandler}
        sx={{
          bgcolor: customColors.white[0],
          paddingY: 0,
          columnGap: "12px",
          borderRadius: "4px",

          "&:hover": {
            bgcolor: customColors.gray[100],
          },
        }}
      >
        <Stack sx={{ alignItems: "flex-end" }}>
          <Typography variant="subtitle3b" sx={{ color: customColors.gray[800] }}>
            {displayName}
          </Typography>
        </Stack>

        <Avatar
          sx={{
            width: "40px",
            height: "40px",
            bgcolor: customColors.gray[300],
            fontSize: "1rem",
          }}
        />
      </Button>

      <Submenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        size="h-40"
        handleClose={closeMenuHandler}
        items={Object.values(menuItems)}
        clickHandler={menuItemClickHandler}
        sx={{
          "& .MuiSvgIcon-root": {
            fill: "none",
          },
        }}
      />

      <Modal
        actionButtonDataTestId="logout-confirm-button"
        actionButtonDisabled={isLeaving}
        actionButtonLabel="Confirm"
        actionButtonLoading={isLeaving}
        actionHandler={logoutConfirmHandler}
        content={logoutContent}
        open={logoutConfirmPopupOpen}
        title="Confirm logout"
        onCancel={logoutCancelHandler}
        onHide={logoutCancelHandler}
      />
    </>
  );
};

export default UserMenu;
