import { CircularProgress, Stack } from "@mui/material";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useAuth } from "react-oidc-context";
import { createBrowserRouter, Navigate, Outlet, useLocation } from "react-router-dom";

import App from "../App";
import AuthenticationStore from "../auth";
import ErrorBoundaryDisplay from "../components/ErrorBoundaryDisplay";
import { FullPageWidthLayout, LeftSidebarLayout } from "../components/Layout";
import { RoutePathType } from "../enums";
import UserMembershipProvider from "../lib/contexts/UserMembershipContext";

/**
 * TODO: LAZY LOAD THE DocEditor COMPONENT
 * Currently theres an issue with the lazy loading of the component / react suspense
 * where infinite rendering of the component happens
 */
import DocEditor from "../pages/Main/DocEditor";
import RedirectPage from "../pages/Main/RedirectPage";
import { getEnv } from "../utils/get_env";

const SignIn = React.lazy(() => import("../pages/Authentication/SignIn"));

const UsersPage = React.lazy(() => import("../pages/Main/Admin/UsersPage"));
const AgentPage = React.lazy(() => import("../pages/Main/Admin/AgentPage"));
const AdminPanel = React.lazy(() => import("../pages/Main/Admin/AdminPanel"));

const AllModels = React.lazy(() => import("../pages/Main/AllModels"));

const File = React.lazy(() => import("../pages/Main/File"));

const Artifact = React.lazy(() => import("../pages/Main/File/Artifact"));

const Settings = React.lazy(() => import("../pages/Main/Settings"));

const Upload = React.lazy(() => import("../pages/Main/Upload"));

const AccessDenied = React.lazy(() => import("../components/AccessDenied"));

const PageNotFound = React.lazy(() => import("../components/PageNotFound"));

const AllMagicDocs = React.lazy(() => import("../pages/Main/AllMagicDocs"));

function SuspenseFallback() {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Stack>
  );
}

function UserMemPagesLayout() {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <UserMembershipProvider>
        <Outlet />
      </UserMembershipProvider>
    </Suspense>
  );
}

function AppWithErrorBoundary() {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryDisplay}>
      <App />
    </ErrorBoundary>
  );
}

function AuthManager() {
  const location = useLocation();
  const auth = useAuth();
  const AS = AuthenticationStore();
  const redirectPath = AS.getRedirectPath();

  return auth.isAuthenticated ? (
    <Suspense fallback={<SuspenseFallback />}>
      {redirectPath ? <RedirectPage redirectUrl={redirectPath} /> : <Outlet />}
    </Suspense>
  ) : (
    <Navigate to={`${RoutePathType.Root}${RoutePathType.SignIn}`} state={{ from: location }} replace />
  );
}

export const router = createBrowserRouter(
  [
    {
      path: RoutePathType.Root,
      element: <AppWithErrorBoundary />,

      children: [
        {
          path: RoutePathType.SignIn,
          element: <SignIn />,
        },
        // MAIN PAGES
        {
          // ---------- THE AUTH MANAGER WRAPPER FOR MAIN PAGES
          element: <AuthManager />,
          children: [
            {
              element: <UserMemPagesLayout />,
              children: [
                {
                  path: "/",
                  // NAVIGATE TO THE HOME PAGE BY DEFAULT (CURRENTLY THE UPLOAD PAGE)
                  element: <Navigate to={`./${RoutePathType.Upload}`} />,
                },
                {
                  /**
                   * ---------- START OF SIDEBAR PAGES ----------
                   */
                  element: <LeftSidebarLayout />,
                  children: [
                    // ---------- THE UPLOAD PAGE
                    {
                      path: RoutePathType.Upload,
                      element: <Upload />,
                    },
                    // ---------- THE SETTINGS PAGE
                    {
                      path: RoutePathType.Settings,
                      element: <Settings />,
                    },
                    // ---------- THE ALL MODELS PAGE
                    {
                      path: RoutePathType.AllModels,
                      element: <AllModels />,
                    },
                    // ---------- THE ADMIN CONSOLE PAGE
                    {
                      path: RoutePathType.AdminPanel,
                      element: <AdminPanel />,
                    },
                    {
                      path: `${RoutePathType.AdminPanel}/users`,
                      element: <UsersPage />,
                    },
                    {
                      path: `${RoutePathType.AdminPanel}/agent`,
                      element: <AgentPage />,
                    },
                    {
                      path: RoutePathType.MagicDocs,
                      element: <AllMagicDocs />,
                    },
                  ],
                  // ------------------------ END OF SIDEBAR PAGES ------------------------
                },

                /**
                 * ------------------------ START OF FULL WIDTH PAGES ------------------------
                 */
                {
                  element: <FullPageWidthLayout />,
                  children: [
                    // ---------- THE FILE PAGE
                    {
                      path: `${RoutePathType.File}/:fileId`,
                      element: <File />,
                    },
                    // ---------- THE FILE ARTIFACT PAGE
                    {
                      path: `${RoutePathType.File}/:fileId/artifact/:artifactId`,
                      element: <Artifact />,
                    },
                    // ---------- THE MAGIC DOC EDITOR PAGE
                    {
                      path: `${RoutePathType.MagicDocs}/:docId`,
                      element: <DocEditor />,
                    },
                    {
                      path: `${RoutePathType.MagicDocs}/draft`,
                      element: <DocEditor isDraftPage />,
                    },
                    // ---------- THE ACCESS DENIED PAGE
                    {
                      path: RoutePathType.AccessDenied,
                      element: <AccessDenied />,
                    },
                    // ---------- THE PAGE NOT FOUND PAGE
                    {
                      path: RoutePathType.NotFound,
                      element: <PageNotFound />,
                    },
                    // ---------- THE PAGE NOT FOUND PAGE
                    {
                      path: "*",
                      element: <PageNotFound />,
                    },
                  ],
                },
              ],
              // ------------------------ END OF OF FULL WIDTH PAGES ------------------------
            },
          ],
        },
        //   -------------------------------- END OF MAIN PAGES --------------------------------
      ],
    },
  ],
  {
    basename: getEnv("BASE_URL"),
  },
);
