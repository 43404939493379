import { ChevronRight } from "@mui/icons-material";
import { Box, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { Clock } from "react-feather";
import { useTranslation } from "react-i18next";

import { useGetMagicDocDerivedArtifact } from "../../../../api/useMagicDocs";
import IstariAccordion from "../../../../components/IstariAccordion";
import { IstariAccordionDetails, IstariAccordionSummary } from "../../../../components/IstariAccordion/IstariAccordion";
import IstariButton from "../../../../components/IstariButton";
import { RoutePathType } from "../../../../enums";
import { customColors } from "../../../../lib/utils/colors";
import { formatDate } from "../../../../utils/date";
import { getFileSVGIconByFileType } from "../../../../utils/file";

interface ThreadItemDetailProps {
  icon: React.ElementType;
  name: string;
  value: string;
  href?: string;
}

const ThreadItemDetail: React.FC<ThreadItemDetailProps> = (props) => (
  <Stack
    direction="row"
    columnGap="4px"
    component={props.href ? "a" : "div"}
    href={props.href}
    sx={{
      cursor: props.href ? "pointer" : "default",
    }}
  >
    {/* Icon */}
    <SvgIcon
      component={props.icon}
      sx={{ fontSize: "1.5rem", fill: "none", color: customColors.gray[400] }}
      inheritViewBox
    />

    {/* Text */}
    <Stack overflow="hidden">
      <Typography variant="caption" color={customColors.gray[400]}>
        {props.name}
      </Typography>

      <Typography noWrap variant="caption" color={props.href ? customColors.primary[400] : customColors.gray[800]}>
        {props.value}
      </Typography>
    </Stack>
  </Stack>
);

interface ThreadItemProps {
  icon: React.ElementType | string;
  title: string;
  outdated?: boolean;
  modelId?: string;
  artifactId?: string;
  onUpdate?: React.MouseEventHandler<HTMLButtonElement>;
  isUpdating?: boolean;
  defaultExpanded?: boolean;
}

const ThreadItem: React.FC<ThreadItemProps> = (props) => {
  // local state
  const [expanded, setExpanded] = React.useState(false);
  const isIDsProvided = React.useRef(!!props.artifactId && !!props.modelId);

  // hooks
  const { t } = useTranslation();

  // get derived artifact data
  const { data, isLoading } = useGetMagicDocDerivedArtifact(
    { modelId: props.modelId || "", artifactId: props.artifactId || "" },
    // Enable on expand. Also, DON'T ENABLE IF IDs ARE NOT PROVIDED
    { enabled: expanded && isIDsProvided.current },
  );

  return (
    <IstariAccordion
      sx={{ marginX: "-20px" }}
      defaultExpanded={props.defaultExpanded}
      // Expand after data is loaded
      expanded={expanded && !isLoading}
    >
      <IstariAccordionSummary
        data-testid="thread-item"
        onClick={() => {
          // do nothing if ID not provided
          if (isIDsProvided.current) setExpanded((prev) => !prev);
        }}
        expandIcon={
          <ChevronRight
            sx={{
              color: customColors.gray[800],
              fontSize: "1rem",
              // hidden if IDs not provided
              visibility: isIDsProvided.current ? "visible" : "hidden",
            }}
          />
        }
        sx={{
          position: "relative",
          minHeight: "auto",
          padding: "8px 20px 8px 4px",
          "& .MuiAccordionSummary-content": {
            padding: 0,
            overflow: "hidden",
          },
        }}
      >
        <Stack overflow="hidden" direction="row" columnGap="4px" alignItems="center">
          {/* --- Icon --- */}
          {isLoading && expanded ? (
            <CircularProgress size={20} />
          ) : (
            <Stack
              sx={{
                minWidth: "20px",
                minHeight: "20px",
                borderRadius: "4px",
                border: `1px solid ${customColors.gray[200]}`,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {typeof props.icon === "string" ? (
                <Typography variant="subtitle4b" color={customColors.gray[800]}>
                  {props.icon}
                </Typography>
              ) : (
                <SvgIcon
                  component={props.icon}
                  inheritViewBox
                  sx={{ color: customColors.gray[800], fontSize: "1rem" }}
                />
              )}
            </Stack>
          )}

          {/* --- Title --- */}
          {/**
           * TODO: IMPLEMENT TEXT TRUNCATING
           */}
          <Typography noWrap variant="subtitle4b" color={customColors.gray[800]}>
            {props.title}
          </Typography>
        </Stack>

        {/* --- Outdated indicator / Blue dot --- */}
        {props.outdated && (
          <Box
            sx={{
              width: "6px",
              height: "6px",
              borderRadius: "50%",
              backgroundColor: customColors.primary[400],
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          />
        )}
      </IstariAccordionSummary>

      <IstariAccordionDetails sx={{ padding: "8px 20px", display: "flex", flexDirection: "column", rowGap: "8px" }}>
        {/* --- File / the artifact referred to --- */}
        <ThreadItemDetail
          icon={getFileSVGIconByFileType(data?.artifact_extension)}
          name={t("common.file")}
          value={data?.name}
          href={`/${RoutePathType.File}/${data?.model_id}/artifact/${data?.id}`}
        />

        {/* --- Derived from --- */}
        <ThreadItemDetail
          icon={getFileSVGIconByFileType(data?.derived_from?.model_type)}
          name={t("common.derivedFrom")}
          value={data?.derived_from?.name}
          href={`/${RoutePathType.File}/${data?.derived_from?.id}`}
        />

        {/* --- Last update --- */}
        <ThreadItemDetail
          icon={Clock}
          name={t("common.lastUpdate")}
          value={formatDate(data?.updated_at, false, true)}
        />

        {/* --- Update Button --- */}
        <IstariButton
          data-testid="thread-update-button"
          variant="lightBg"
          color="gray"
          size="h-32"
          onClick={props.onUpdate}
          disabled={!props.outdated || props.isUpdating}
          loading={props.isUpdating}
          sx={{
            alignSelf: "flex-end",
            // TODO: Remove this style after implementing the update functionality
            display: "none",
          }}
        >
          {t("common.update")}
        </IstariButton>
      </IstariAccordionDetails>
    </IstariAccordion>
  );
};

export default ThreadItem;
