/**
 * Converts Editor.js data to a Jupyter Notebook format.
 *
 * @param {Object} editorJsData - The data from Editor.js.
 * @returns {void}
 */
import { OutputBlockData, OutputData } from "@editorjs/editorjs";
import TurndownService from "turndown";

import { fetch_code_template } from "./convertToJupyterNotebookTemplate";

export enum CellType {
  MARKDOWN = "markdown",
  CODE = "code",
}

export interface Cell {
  cell_type: CellType; // Literal type
  execution_count?: number | null; // Nullable number for execution_count
  metadata: Record<string, any>; // Empty object, metadata can contain any properties
  outputs?: any[]; // Array of any type for outputs (assuming it's an array of objects)
  source: string[]; // Array of strings
}

interface Notebook {
  nbformat: number;
  nbformat_minor: number;
  metadata: {
    language_info: {
      name: string;
      version: string;
    };
  };
  cells: Cell[]; // Assuming cells can be of any type of array
}

function handleCodeBlockType(block: OutputBlockData<string, any>): Cell {
  return {
    cell_type: CellType.CODE,
    execution_count: null,
    metadata: {},
    outputs: [],
    source: [`${block.data.code}\n`],
  };
}

function handleHeaderBlockType(block: OutputBlockData<string, any>): Cell {
  const text = `${"#".repeat(block.data.level)} ${block.data.text}`;
  return {
    cell_type: CellType.MARKDOWN,
    metadata: {},
    source: [`${text}\n`],
  };
}

function handleMagicLinkBlockType(block: OutputBlockData<string, any>): Cell {
  const code = `# This is an Istari magic link 
artifact = fetch_artifact('${block.data.meta.model_id}', '${block.data.meta.artifact_id}')
# Check the asset type and call the corresponding render function
if artifact and 'asset_type' in artifact['meta'] and 'asset_url' in artifact['meta']:
\tasset_url = artifact['meta']['asset_url']
\tasset_extension = artifact['meta']['asset_type']
\trender_asset_based_on_extension(asset_url, asset_extension)
else:
\tprint("Invalid artifact or asset type/URL not found")
`;
  return {
    cell_type: CellType.CODE,
    execution_count: null,
    metadata: {},
    outputs: [],
    source: [`${code}\n`],
  };
}

function handleDefaultBlockType(block: OutputBlockData<string, any>): Cell {
  const blockElement = document.querySelector(`[data-id='${block.id}']`);
  if (blockElement === null) {
    throw new Error(`Error, did not find element for ${block.id}`);
  }
  // Convert other block types to Markdown using Turndown service
  const turndownService = new TurndownService();
  const markdown = turndownService.turndown(blockElement.innerHTML);
  return {
    cell_type: CellType.MARKDOWN,
    metadata: {},
    source: [`${markdown}\n`],
  };
}

export function handleMagicDocBlock(block: OutputBlockData<string, any>): Cell {
  if (block.type === "code") {
    return handleCodeBlockType(block);
  }
  if (block.type === "header") {
    return handleHeaderBlockType(block);
  }
  if (block.type === "magicLink") {
    return handleMagicLinkBlockType(block);
  }
  return handleDefaultBlockType(block);
}

export function convertEditorJsToJupyter(editorJsData: OutputData): Notebook {
  const notebook: Notebook = {
    nbformat: 4,
    nbformat_minor: 4,
    metadata: {
      language_info: {
        name: "python",
        version: "3.7.3",
      },
    },
    cells: [],
  };

  // Add an introductory markdown cell for specifying the token
  notebook.cells.push({
    cell_type: CellType.MARKDOWN,
    metadata: {},
    source: ["Please specify your PAT token in order to run this notebook and call Istari APIs.\n"],
  });

  // Add a code cell with the fetch code
  notebook.cells.push({
    cell_type: CellType.CODE,
    execution_count: null,
    metadata: {},
    outputs: [],
    source: [`${fetch_code_template}\n`],
  });

  // Iterate through the Editor.js blocks and convert them to Jupyter cells
  const blocks = editorJsData.blocks.map((b) => handleMagicDocBlock(b));
  notebook.cells.push(...blocks);

  return notebook;
}
