const keys = {
  translation: {
    common: {
      accessLog: "Access Log",
      version: "Version {{x}}",
      extension: "Extension",
      name: "Name",
      model: "Model",
      original: "Original",
      cameo: "Cameo",
      file: "File",
      upload: "Upload",
      enter: "Enter",
      processing: "Processing",
      uploaded: "Uploaded",
      cancel: "Cancel",
      owner: "Owner",
      values: "Values",
      extracted: "Extracted",
      source: "Source",
      save: "Save",
      draft: "Draft",
      created: "Created",
      completed: "Completed",
      inputs: "Inputs",
      outputs: "Outputs",
      sharing: "Sharing",
      next: "Next",
      back: "Back",
      api: "Api",
      function: "Function",
      functions: "Functions",
      verification: "Verification",
      verify: "Verify",
      result: "Result",
      generating: "Generating",
      view: "View",
      download: "Download",
      share: "Share",
      report: "Report",
      files: "Files",
      recent: "Recent",
      access: "Access",
      wrappers: "Wrappers",
      open: "Open",
      all: "All",
      show: "Show",
      remove: "Remove",
      duplicate: "Duplicate",
      rename: "Rename",
      edit: "Edit",
      found: "Found",
      inputValues: "Input values",
      outputValues: "Output values",
      nodeList: "Node list",
      preview: "Preview",
      cadWrapperDes:
        "We have generated the nodes based on the extracted APIs and values, and added them into a model wrapper",
      sourceFile: "Source File",
      extractedValues: "Extracted Values",
      extractedAPIs: "Extracted APIs",
      manageAccess: "Manage Access",
      click: "Click",
      here: "Here",
      search: "Search",
      private: "Private",
      disable: "Disable",
      node: "Node",
      wrapper: "Wrapper",
      type: "Type",
      input: "Input",
      public: "Public",
      variations: "Variations",
      copy: "Copy",
      link: "Link",
      add: "Add",
      library: "Library",
      continue: "Continue",
      home: "Home",
      backToHomePage: "Back to Home page",
      size: "Size",
      new: "New",
      request: "Request",
      value: "Value",
      output: "Output",
      releasedOn: "Released on",
      username: "Username",
      password: "Password",
      email: "Email",
      confirm: "Confirm",
      reset: "Reset",
      change: "Change",
      variation: "Variation",
      run: "Run",
      saving: "Saving",
      submitting: "Submitting",
      uploading: "Uploading",
      changes: "Changes",
      image: "Image",
      pleaseTryAgain: "Please try again",
      lastUpdate: "Last update",
      copyLink: "Copy Link",
      processed: "Processed!",
      retry: "Retry",
      requirements: "Requirements",
      reports: "Reports",
      description: "Description",
      parameters: "Parameters",
      parameter: "Parameter",
      specifications: "Specifications",
      specification: "Specification",
      select: "Select",
      min: "MIN",
      max: "MAX",
      equals: "Equals",
      range: "Range",
      verifySelected: "Verify Selected",
      unit: "Unit",
      close: "Close",
      selectLabel: "variables",
      selectPlaceholder: "Select variable",
      variable: "Variable",
      valuePlaceholder: "Enter value",
      export: "Export",
      derivedFrom: "Derived from",
      lastUpdated: "Last updated",
      update: "Update",
      shared: "Shared",
      level: "Level",
      create: "Create",
      untitledDoc: "Untitled Document",
    },

    accessLogs: {
      title: "Access Logs",
      error: "Error loading access logs",
      noLogs: "No access logs found",
      allUsers: "All Users",
    },

    header: {
      userMenu: {
        profile: "Profile settings",
        logout: "Log out",
      },
    },

    sideBar: {
      navItems: {
        home: "Home",
        allModels: "All Models",
        settings: {
          label: "Settings",
          children: {
            profileSettings: "Profile Settings",
            changePassword: "Change Password",
            developerSettings: "Developer Settings",
          },
        },
        documentation: "Documentation",
        adminPanel: "Admin Panel",
        magicDocs: "Magic Docs",
      },
    },

    upload: {
      title: "Upload, Configure, and Share models securely",
      description: "Upload files to generate a model API",
      supportedFiles: "Supported files",

      uploadZone: {
        description: "Click on the area to upload files",
        helperText: "or drag and drop files here",
        uploadFromUrl: "Upload from URL",
      },

      tryAgain: "Failed to upload the file. Try to upload it again.",
      uploadViaUrlPopup: {
        title: "Upload from a URL",
        description: "Add a URL to upload the file. It can only be a public link.",
        inputPlaceholder: "Add a URL",
        invalidUrl: "Invalid URL, try again!",
      },
      onDragMsg: "Drop your file here",

      recentWrappers: {
        title: "Recent Wrappers",
        link: {
          label: "All Models",
        },
        noWrappers: "There are no models used recently yet...",
      },

      statusRow: {
        uploadingError: "File was not uploaded",
        messages: {
          models: {
            uploading: {
              0: "Securing Model On your Own servers...",
              1: "Making your model securely sharable...",
            },
            notSupported: {
              0: "This file format cannot be processed yet",
            },
            forbidden: {
              0: "Sorry, this file Is NOT permitted for security reasons",
            },
            processing: {
              0: "Analyzing the model for completeness and integrity...",
              1: "Wrapping model with relevant APIs...",
              2: "Generating template documentation for your model...",
              3: "Adding an AI training layer to your model...",
            },
            /* processingError: {
              0: "We couldn't extract the file. Upload the file again or try another one.",
            }, */
          },
        },
      },
      statusCard: {
        noFiles: "There are no recently uploaded files yet...",
        title: "Files Uploading & Processing",
      },
    },

    process: {
      title: "NX File Processing",
      description:
        "We are processing the uploaded file and extracting the values for modeling, this may take a few minutes.",
      statusMessage: "Analyzing the model for completeness and integrity...",
      // failedMessage: "We couldn't extract the file. Upload the file again or try another one.",
    },

    filePage: {
      list: {
        title: "Generated Model Wrappers",
        noWrappers: "No wrappers created yet, add your first wrapper",
        noWrappersFound: "No wrappers found!",
      },

      shareModal: {
        title: "Share File",
      },

      createNewWrapper: {
        failed: "Failed to create new wrapper. Try again!\n{{msg}}",
      },
    },

    wrapperPage: {
      backToFile: "Main File",

      outputs: {
        noFunctionsAdded:
          "No functions added!\nClick on <strong>(+ New Output)</strong> and select a function to display new output field.",
        diagramsTree: {
          selectLabel: "Select a diagram",
          noDiagram: "No diagram selected",
          popoverHeader: "Choose the diagram",
        },
        generating: {
          message: "We generate the result based on the applied input values. This may take a few minutes.",
        },
      },

      inputs: {
        inputsForm: {
          actions: {
            submit: "Submit",
            saveVariation: "Save Variation",
            reset: "Reset to default data",
          },
        },

        noFunctionsAdded:
          "No functions added!\nClick on <strong>(+ New Input)</strong> and select a function to display new input field.",
      },

      variationsTab: {
        noData: "No saved variations yet",
        error: "Something went wrong!\n{{msg}}",
      },
    },

    modals: {
      manageAccess: {
        title: "Share {{modelName}}",
        helperText: "Only people from your organization can get access to the model",
        noUsers: "",
        search: {
          placeholder: "Search a user...",
          noResult: "No users found",
        },
      },
      apiFunctions: {
        title: "Function Library",
        description: "Select a function to add an API to the wrapper",
        search: {
          placeholder: "Search APIs...",
        },
      },
      shareOutputPopup: {
        title: "Share link to {{for}} of the model",
      },
    },

    confirmationPopups: {
      delete_model: {
        title: "Do you want to delete this model {{modelType}}?",
        description:
          "The action cannot be undone! The model <strong>({{modelName}})</strong> will be permanently removed!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      delete_file: {
        title: "Do you want to delete this model file?",
        description:
          "The action cannot be undone, the model file <strong>({{fileName}})</strong> will be permanently removed!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      delete_wrapper: {
        title: "Do you want to delete this model wrapper?",
        description:
          "The action cannot be undone, the model wrapper <strong>({{wrapperName}})</strong> will be permanently removed!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      delete_variation: {
        title: "Do you want to delete this wrapper variation?",
        description:
          "The action cannot be undone, the variation <strong>({{variationName}})</strong> will be permanently removed!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      cancel_wrapper_creation: {
        title: "Do you want to stop the model wrapper creation process?",
        description: "All extracted values and generated nodes will be lost!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      delete_node: {
        title: "Do you want to delete this node?",
        description:
          "The action cannot be undone, the node <strong>({{nodeName}})</strong> will be permanently removed from this wrapper!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },
      duplicate_file: {
        title: "Do you want to duplicate this model file?",
        description: "The model file <strong>({{modelName}})</strong> will be duplicated with no wrappers attached!",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },

      duplicate_wrapper: {
        title: "Do you want to duplicate this model wrapper?",
        description: "",
        actions: {
          cancel: "Cancel",
          confirm: "Confirm",
        },
      },
    },

    renamePopup: {
      title: "Enter the new name for the {{for}}",
      helperText: "Must be 3-55 characters long",
      errorMsgs: {
        min: "The name must be at least 3 characters long",
        max: "The name must be at most 55 characters long",
        required: "Please enter the new name",
      },
      placeholder: "Enter the new title",
    },

    accessDenied: {
      title: "Access denied!",
      msg: "You do not have access to this model. You need to get permission from the model owner in order to access it.",
    },

    errorBoundary: {
      title: "Oh no! An error has occurred.",
      msg: "Looks like we’ve encountered an issue. It has been reported to our team and we will get around to fixing it soon.",
    },

    pageNotFound: {
      title: "Page does not exist",
      msg: "The page does not exist or has been moved. Make sure that the link is correct and not corrupted.",
    },

    magicDocsPage: {
      title: "Istari Magic Docs",
      desc: "Istari Magic Docs are a special case of a digital thread where the only interactions with models are to ‘extract’ data and where there is no interaction between the extracted data.",
      actions: {
        createDoc: "New Magic Doc",
        viewAllDocs: "All Magic Docs",
      },

      aboutIt: {
        title: "Useful when you want to...",
        usages: {
          0: "...integrate data from many different engineering models or data sources",
          1: "...ensure that a document contains the most up to date information from your models or data sources",
          2: "...allow for personalized display of data based upon right to know and need to know ",
        },
      },
    },

    magicDocPrevPage: {
      breadcrumbsLink: "All Magic Docs",
      sideBar: {
        tabs: {
          outline: "Outline",
          thread: "Thread",
        },
      },
      docSaveSuccess: "Changes saved successfully!",
      docSaveFailed: "Failed to save the changes!\n{{msg}}",
      docCreateSuccess: "New magic doc created successfully!",
      docCreateFailed: "Failed to create a new magic doc.\n{{msg}}",
      editRequestFailed: "Failed to request edit access!\n{{msg}}",
      docLinkCopied: "Link copied to clipboard",
      docLinkCopyFailed: "Failed to copy the link to the clipboard",
      docLocked: "This magic doc is locked for editing by another user",
      shareDocModalTitle: "Share Magic Doc",
      leaveConfirmModal: {
        content: "You have unsaved changes. Are you sure you want to leave?",
        title: "Discard changes",
      },
      beingEdited: "is being edited by",
    },
    allMagicDocsPage: {
      breadcrumbs: {
        0: "Istari Magic Docs",
        1: "All Magic Docs",
      },
      actions: {
        upload: "Upload Doc",
        create: "New Magic Doc",
        createLoading: "Creating draft ...",
      },
      listingTableHeaders: {
        name: "Name",
        date: "Last Update",
        owner: "Owner",
        infosec_level: "Infosec Level",
        type: "Type",
      },

      tabs: {
        allDocs: "All Magic Docs",
        myDocs: "My Magic Docs",
        sharedWithMe: "Shared with me",
      },
      noDocsPlaceholder: "There are no magic docs yet...",
      errorPlaceholder: "Failed to load magic docs!\n{{msg}}",
    },
  },
};

export default keys;
