import i18n from "../I18n/index";

/**
 * return styled title to be used in page title
 *
 * @param {string} title
 * @returns {string}
 */
export const getCommonPageTitle = (title: string) =>
  title ? `${i18n.t("Istari Digital")} | ${i18n.t(title)}` : i18n.t("Istari Digital");

/**
 * reformatting numbers => en-US
 * rounded at most to two decimals
 * 99999.99999 => 99,999.99
 *
 * @param {number} number
 * @returns {string}
 */
export const numberFormatter = (number: number) => {
  const formatter = Intl.NumberFormat("en-US");
  return formatter.format(Math.round((number + Number.EPSILON) * 100) / 100);
};

/**
 *
 * @param {number} bytes
 * @param {number} decimals
 * @returns string
 */
export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

/**
 *
 * @returns {String} unique id
 * TODO: use the uuid in javascript: crypto.randomUUID()
 */
export const generateUniqueId = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

/**
 *
 * @param {string} src
 * @returns {Promise}
 */
export const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img);
    };

    img.onerror = () => {
      reject(src);
    };

    img.onabort = () => {
      reject(src);
    };

    img.src = src;
  });

/**
 * Get the dirty values from a form
 * @param {array | object} dirtyFields
 * @param {array | object} allValues
 * @returns
 */
export const getDirtyValues = (dirtyFields: any, allValues: any): object => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, getDirtyValues(dirtyFields[key], allValues[key])]),
  );
};

/**
 * Disable storybook controls
 * @param {array} controls (props)
 * @returns {object} object with disabled controls
 */
export const disableControls = (controls: any[]) =>
  controls.reduce((acc, curr) => ({ ...acc, [curr]: { control: false } }), {});

/**
 * Hide storybook controls
 * @param {array} controls (props)
 * @returns {object} object with disabled controls
 */
export const hideControls = (controls: any[]) =>
  controls.reduce((acc, curr) => ({ ...acc, [curr]: { table: { disable: true } } }), {});

/**
 *
 * @param {string} charA
 * @param {string} CharZ
 * @returns {array} - array of chars
 */
export const generateCharArray = (charA: string, charZ: string) => {
  const a = [];
  let i = charA.charCodeAt(0);
  const j = charZ.charCodeAt(0);
  for (; i <= j; i++) {
    a.push(String.fromCharCode(i));
  }

  return a;
};
