import { useQuery } from "@tanstack/react-query";

import { fileService } from "../services";
import { usersQueryKeys } from "./queryKeys";

export interface OrgUser {
  user: string;
  family_name: string;
  given_name: string;
  name: string;
  sub: string;
  id: number;
}

export const useListOrgUsers = () => useQuery(usersQueryKeys.all, () => fileService.get(`/user/?size=10000`));

export const useGetOrgUserBySub = (sub: string) =>
  useQuery(usersQueryKeys.item(sub), () =>
    fileService.get(`/user/?size=10000`, { params: { sub } }).then((res) => res.items?.[0]),
  );
