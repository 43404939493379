import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Divider, Stack, SxProps, Theme } from "@mui/material";
import React, { Fragment } from "react";
import { ChevronLeft } from "react-feather";
import { useNavigate } from "react-router-dom";

import { customColors } from "../../lib/utils/colors";
import MoreOptions, { MoreOptionsProps } from "../FilesAccordionsList/partials/MoreOptions";
import IstariButton from "../IstariButton";
import IstariIconButton from "../IstariIconButton";

export const ACTIONS_BAR_HEIGHT = 48;

interface BaseButton {
  onClick: () => void;
  sx?: SxProps<Theme>;
  loading?: boolean;
  disabled?: boolean;
  testId: string;
}

interface IconButton extends BaseButton {
  type: "icon";
  icon: React.ElementType;
}

interface LabeledButton extends BaseButton {
  type: "primary" | "secondary" | "gray";
  variant?: "colored" | "outlined" | "lightBg";
  label: string;
  startIcon?: React.ElementType;
  endIcon?: React.ElementType;
  loadingMsg?: string;
}

export type ActionButton = IconButton | LabeledButton;

export type ActionsBarProps = {
  actionBtns: ActionButton[][];
  additionalDetails?: React.ReactNode;
  breadcrumbs: React.ReactNode[];
  noBackButton?: boolean;
  moreOptions?: {
    onOptionCLick: MoreOptionsProps["optionClickHandler"];
    options: MoreOptionsProps["options"];
  };
};
const ActionsBar: React.FC<ActionsBarProps> = (props) => {
  const navigate = useNavigate();
  /**
   * Map the action buttons components
   */
  const actionButtons = React.useMemo(
    () =>
      props.actionBtns.map((list, i) => {
        const btnsGroup = list.map((btn, j) => {
          switch (btn.type) {
            case "primary":
              return (
                <IstariButton
                  data-testid={btn.testId}
                  variant={btn.variant || "colored"}
                  key={btn.label}
                  size="h-32"
                  onClick={btn.onClick}
                  startIcon={btn.startIcon}
                  endIcon={btn.endIcon}
                  loadingMessage={btn.loadingMsg}
                  loading={btn.loading}
                  disabled={btn.disabled || btn.loading}
                  sx={btn.sx}
                >
                  {btn.label}
                </IstariButton>
              );
            case "secondary":
              return (
                <IstariButton
                  data-testid={btn.testId}
                  key={btn.label}
                  size="h-32"
                  onClick={btn.onClick}
                  startIcon={btn.startIcon}
                  endIcon={btn.endIcon}
                  loadingMessage={btn.loadingMsg}
                  loading={btn.loading}
                  disabled={btn.disabled || btn.loading}
                  color="gray"
                  variant={btn.variant || "colored"}
                  sx={btn.sx}
                >
                  {btn.label}
                </IstariButton>
              );
            case "gray":
              return (
                <IstariButton
                  data-testid={btn.testId}
                  key={btn.label}
                  size="h-32"
                  onClick={btn.onClick}
                  startIcon={btn.startIcon}
                  endIcon={btn.endIcon}
                  loadingMessage={btn.loadingMsg}
                  loading={btn.loading}
                  disabled={btn.disabled || btn.loading}
                  color="gray"
                  variant={btn.variant || "colored"}
                  sx={btn.sx}
                >
                  {btn.label}
                </IstariButton>
              );
            case "icon":
            default:
              return (
                <IstariIconButton
                  data-testid={btn.testId}
                  key={`icon-button-${i}-${j}`}
                  onClick={btn.onClick}
                  Icon={btn.icon}
                  loading={btn.loading}
                  disabled={btn.disabled || btn.loading}
                  variant="lightBg"
                  color="gray"
                  type="square"
                  size={32}
                  sx={btn.sx}
                />
              );
          }
        });

        return (
          <Fragment key={`btns-group-${i}`}>
            {/* IF NOT first LIST AND PREV LIST HAS ITEMS --> ADD DIVIDER */}
            {i >= 1 && !!props.actionBtns[i - 1]?.length && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            <Stack
              direction="row"
              sx={{
                columnGap: "8px",
                alignItems: "center",
              }}
            >
              {btnsGroup}
            </Stack>
          </Fragment>
        );
      }),
    [props.actionBtns],
  );

  return (
    <Stack
      className="actions-bar"
      direction="row"
      justifyContent="space-between"
      padding="8px 20px"
      columnGap="16px"
      sx={{
        borderBottom: `1px solid ${customColors.gray[100]}`,
        height: ACTIONS_BAR_HEIGHT,
        // Media Print styles
        "@media print": {
          display: "none",
        },
      }}
    >
      {/* ------------------------------ LEFT SIDE */}
      <Stack direction="row" columnGap="16px" alignItems="center">
        {/* ----- Back Button ----- */}
        {!props.noBackButton && (
          <IstariIconButton
            data-testid="actionsBar_back-button"
            Icon={ChevronLeft}
            onClick={() => {
              navigate(-1);
            }}
            variant="lightBg"
            color="gray"
            type="square"
            size={32}
            sx={{
              fill: "none",
            }}
          />
        )}

        {/* ----- Breadcrumbs ----- */}
        <Breadcrumbs
          data-testid="actionsBar_breadcrumbs"
          separator={<NavigateNext fontSize="small" />}
          sx={{
            "& .MuiBreadcrumbs-li": {
              "&:first-of-type": {
                color: customColors.gray[500],
              },
              "&:last-of-type": {
                color: customColors.gray[800],
              },
            },
            "& .MuiBreadcrumbs-separator": {
              color: customColors.gray[400],
            },
          }}
        >
          {props.breadcrumbs}
        </Breadcrumbs>

        {/* ----- More options Button ----- */}
        {!!props.moreOptions && (
          <MoreOptions optionClickHandler={props.moreOptions?.onOptionCLick} options={props.moreOptions?.options} />
        )}
      </Stack>

      {/* ------------------------------ RIGHT SIDE */}
      <Stack direction="row" columnGap="16px" alignItems="center">
        {/* ----- additional details ----- */}
        {props.additionalDetails}

        {/* ----- divider ----- */}
        {/* SHOWN ONLY IF ADDITIONAL DETAILS PROVIDED */}
        {!!props.additionalDetails && <Divider orientation="vertical" variant="middle" flexItem />}

        {/* ----- action buttons ----- */}
        <Stack
          direction="row"
          sx={{
            columnGap: "16px",
            alignItems: "center",
          }}
        >
          {actionButtons}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ActionsBar;
