/* eslint-disable class-methods-use-this */
export default class TestIdTune {
  wrap(blockContent: HTMLDivElement) {
    // Add the data-testid attribute to the block
    blockContent.setAttribute("data-testid", "md-block");
    // Add the data-testid attribute to the block content
    blockContent.firstElementChild?.setAttribute("data-testid", "md-block-content");
    return blockContent;
  }

  save() {
    return "";
  }

  render() {
    return document.createElement("div");
  }

  static get isTune() {
    return true;
  }
}
