import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Collapse, Divider, Drawer, List, ListItem, Stack, SvgIcon, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { ExternalLink, Minus } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";

import packageJson from "../../../../package.json";
import { IstariLogoSecondary } from "../../../assets/svg";
import { customColors } from "../../../lib/utils/colors";
import { getEnv } from "../../../utils/get_env";
import { SideBarProps } from "../types";

interface SubListProps {
  open: boolean;
  children: React.ReactNode | React.ReactNode[];
}
const SubList: React.FC<SubListProps> = ({ open, children }) => (
  <Collapse component="li" in={open} timeout="auto">
    <List
      sx={{
        p: 0,
        "& .MuiListItem-root": {},
      }}
    >
      {children}
    </List>
  </Collapse>
);

const SideBar: React.FC<SideBarProps> = (props) => {
  const location = useLocation();

  const documentationURL = getEnv("VITE_DOCUMENTATION_URL") || "https://docs.dev.istari.app";

  const getSubNavClassname = (parent: string, child: string, index: number) => {
    if (location.search.includes(child) || (!parent.includes("documentation") && index === 0 && !location.search)) {
      return "sidebar-nav-item active";
    }
    if (parent.includes("documentation")) {
      return "sidebar-nav-item-docs";
    }
    return "sidebar-nav-item";
  };

  /**
   * Render the list of navigation link
   */
  const renderNavListItems = useMemo(
    () =>
      props.navLists?.map((list, i) => (
        <React.Fragment key={`nav-wrapper-${i}`}>
          {i !== 0 && <Divider sx={{ mx: "8px", color: customColors.gray[200], my: "4px" }} />}
          <List
            sx={{
              p: 0,
              px: "8px",
              "& .MuiListItem-root": {},
            }}
          >
            {list.map((item) => (
              <React.Fragment key={item.value}>
                <ListItem key={item.value} sx={{ p: 0, userSelect: "auto" }}>
                  <NavLink
                    tabIndex={0}
                    to={item.value.includes("documentation") ? documentationURL : item.value}
                    className={({ isActive }) => (isActive ? "sidebar-nav-item active" : "sidebar-nav-item")}
                    data-testid={`sidebar-nav-item-${item.value}`}
                    target={item.value.includes("documentation") ? "_blank" : undefined}
                  >
                    {item.icon && (
                      <SvgIcon
                        className={`icon__${item.value}`}
                        component={item.icon}
                        inheritViewBox
                        sx={{ fontSize: "1.5rem", fill: "none" }}
                      />
                    )}

                    <Typography variant="subtitle3" sx={{ color: "inherit" }}>
                      {item.label}
                    </Typography>
                    {item.value.includes("documentation") && (
                      <Stack paddingLeft="5px" sx={{ alignItems: "center", justifyContent: "center" }}>
                        <ExternalLink size="14px" data-testid="documentation-new-tab-icon" />
                      </Stack>
                    )}
                  </NavLink>
                </ListItem>

                {
                  // if the item has children, render the sub-list
                  item.children && (
                    <SubList
                      key={`${item.value}_subList`}
                      open={item.value.includes("documentation") ? true : props.activeNavItem === item.value}
                    >
                      {item.children?.map((child, index) => (
                        <ListItem key={child.value} sx={{ p: 0 }}>
                          <NavLink
                            tabIndex={0}
                            to={item.value.includes("documentation") ? child.value : `${item.value}?tab=${child.value}`}
                            className={() => getSubNavClassname(item.value, child.value, index)}
                            target={item.value.includes("documentation") ? "_blank" : undefined}
                            data-testid={`sidebar-nav-item-${child.value}`}
                          >
                            {/* selection indicator */}

                            {item.value.includes("documentation") ? (
                              <PlayCircleIcon />
                            ) : (
                              <SvgIcon
                                className="subItem_selection-indicator"
                                component={Minus}
                                inheritViewBox
                                sx={{
                                  fontSize: "1rem",
                                  fill: "none",
                                  color: "inherit",
                                  visibility: "hidden",
                                  transition: "all 0.25s ease",
                                }}
                              />
                            )}

                            <Typography
                              variant={item.value.includes("documentation") ? "subtitle3" : "overline"}
                              sx={{ color: "inherit" }}
                            >
                              {child.label}
                            </Typography>
                          </NavLink>
                        </ListItem>
                      ))}
                    </SubList>
                  )
                }
              </React.Fragment>
            ))}
          </List>
        </React.Fragment>
      )),
    [location.search, props.navLists, props.activeNavItem, documentationURL],
  );

  return (
    <Drawer
      variant="permanent"
      anchor={props.anchor || "left"}
      sx={{
        width: props.width || "auto",
        height: "100%",
        flexShrink: 0,
        position: "static",

        [`& .MuiDrawer-paper`]: {
          width: props.width || "auto",
          boxSizing: "border-box",
          padding: 0,
          bgcolor: customColors.gray[100],
          border: "none",
          top: getEnv("VITE_ITAR") === "true" ? "29px" : 0,
        },
      }}
    >
      {/* ---------- Company Logo ---------- */}
      <Stack
        data-testid="sidebar-top-logo"
        sx={{
          height: "60px",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: `1px solid ${customColors.gray[200]}`,
          mb: "16px",
        }}
      >
        {props.logo && props.logo}
      </Stack>

      {/* ----- LIST OF NAV BUTTONS ----- */}
      <nav id="sidebar">{renderNavListItems}</nav>

      {/* ----- APP LOGO & VERSION ----- */}
      <Stack
        id="app-logo-and-version"
        data-testid="sidebar-grey-logo-and-version"
        rowGap="4px"
        alignItems="center"
        sx={{
          position: "absolute",
          bottom: "16px",
          right: "50%",
          transform: "translateX(50%)",
        }}
      >
        <SvgIcon
          inheritViewBox
          component={IstariLogoSecondary}
          sx={{ width: "auto", height: "32px", color: customColors.gray[300] }}
        />

        <Typography variant="subtitle4">Version {packageJson.version}</Typography>
      </Stack>
    </Drawer>
  );
};

export default SideBar;
