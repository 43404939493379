import {
  Autocomplete,
  Avatar,
  Box,
  Dialog,
  FilterOptionsState,
  Grid,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { Lock } from "react-feather";
import { useTranslation } from "react-i18next";

import { ModelCreator, UserGrant, useShareModel } from "../../api/useModels";
import { useListOrgUsers } from "../../api/useUsers";
import AuthenticationStore from "../../auth";
import { UserGrantType } from "../../enums";
import { customColors } from "../../lib/utils/colors";
import { theme } from "../../lib/utils/create-theme";
import { accessOptions } from "../../permissions";
import { trimLowercase } from "../../utils/strings";
import AccessMenu from "../AccessMenu";
import IstariButton from "../IstariButton";
import IstariChip from "../IstariChip";
import { showToast } from "../IstariToast/showToast";

function UserCell({ name }: { name: string }) {
  return (
    <Stack direction="row" columnGap="8px" alignItems="center">
      <Avatar sx={{ width: "32px", height: "32px" }} />
      <Stack>
        <Typography
          variant="subtitle3b"
          sx={{
            color: customColors.gray[800],
          }}
        >
          {name}
        </Typography>
      </Stack>
    </Stack>
  );
}

interface DraftUserType {
  email: string;
  fullName: string;
  grant?: string;
  subId: string | undefined;
  userId: string;
  //
  id?: string;
  sub?: string;
  family_name?: string;
  given_name?: string;
  name?: string;
}

interface ManageAccessPopupProps {
  open: boolean;
  title: string;
  userGrants: UserGrant[];
  whatToShareId?: string;
  onClose: () => void;
  reloadModels: () => void;
  testId?: string;
}

function ManageAccessPopup(props: ManageAccessPopupProps) {
  const { open, title, userGrants, whatToShareId, onClose, reloadModels, testId } = props;
  const usersWithAccess = userGrants.map((item) => ({
    email: item.user.name,
    fullName: `${item.user.given_name} ${item.user.family_name}`,
    grant: item.grant,
    subId: item.user.sub,
    userId: item.user.id,
  }));

  const [draftUsersWithAccess, setDraftUsersWithAccess] = useState<DraftUserType[]>(usersWithAccess);
  const [selectedUsers, setSelectedUsers] = useState<DraftUserType[]>([]);

  const AS = AuthenticationStore();
  const { userId: currentUserSubId } = AS.getCurrentUser();

  const { t } = useTranslation();

  const submitGivenAccessUsers = useShareModel();
  const isSharing = submitGivenAccessUsers.isLoading;

  const { data: orgUsersData, isLoading: isLoadingOrgUsers } = useListOrgUsers();

  const listOfOrgUsers = orgUsersData?.items.map((user: ModelCreator) => ({
    email: user.name,
    fullName: `${user.given_name} ${user.family_name}`,
    subId: user.sub,
    userId: user.id,
  }));

  const listOfUsersWithNoAccess = useMemo(() => {
    const givenAccessUserIds = draftUsersWithAccess?.map((user) => user.userId);

    const selectableUsers = listOfOrgUsers?.filter((user: DraftUserType) => !givenAccessUserIds.includes(user.userId));
    const sortedSeletecatbleUsers = selectableUsers?.sort((a: DraftUserType, b: DraftUserType) =>
      a.fullName.localeCompare(b.fullName),
    );

    return sortedSeletecatbleUsers;
  }, [draftUsersWithAccess, listOfOrgUsers, usersWithAccess]);

  const filterOptions = useCallback(
    (users: DraftUserType[], state: FilterOptionsState<DraftUserType>) =>
      users.filter(
        (user) =>
          trimLowercase(user.email)?.includes(trimLowercase(state.inputValue)) ||
          trimLowercase(user.fullName)?.includes(trimLowercase(state.inputValue)),
      ),
    [],
  );

  const handleSelectedUsers = useCallback((_evt: React.SyntheticEvent, users: DraftUserType[]) => {
    setSelectedUsers(users);
  }, []);

  const handleAddUsers = useCallback(() => {
    const defaultAccessLevelId = accessOptions?.filter((access) => access.label === "viewer")[0].name;

    const newGivenAccessUsers = selectedUsers?.map((user) => ({
      ...(user as DraftUserType),
      grant: defaultAccessLevelId,
    }));

    setDraftUsersWithAccess((prev: DraftUserType[]) =>
      prev ? [...prev, ...newGivenAccessUsers] : ([...usersWithAccess, ...newGivenAccessUsers] as DraftUserType[]),
    );

    setSelectedUsers([]);
  }, [selectedUsers, accessOptions, usersWithAccess]);

  const handleUsersGivenAccess = useCallback(
    (userId: string, grant: UserGrantType) => {
      setDraftUsersWithAccess((prev: DraftUserType[]) =>
        prev
          ? (prev.map((user: DraftUserType) => (user.userId === userId ? { ...user, grant } : user)) as DraftUserType[])
          : (usersWithAccess?.map((user) => (user.userId === userId ? { ...user, grant } : user)) as DraftUserType[]),
      );
    },
    [usersWithAccess],
  );

  const handleRemoveUserAccess = useCallback(
    (_event: React.SyntheticEvent, removedUserId: string) => {
      setDraftUsersWithAccess((prev: DraftUserType[]) =>
        prev
          ? ([...prev.filter((user: DraftUserType) => !(user.userId === removedUserId))] as DraftUserType[])
          : ([...usersWithAccess.filter((user: DraftUserType) => !(user.userId === removedUserId))] as DraftUserType[]),
      );
    },
    [usersWithAccess],
  );

  const cancelHandler = useCallback(() => {
    onClose();
    setSelectedUsers([]);
    setDraftUsersWithAccess([]);
  }, [props]);

  const handleSaveGivenAccessUsers = useCallback(() => {
    const draftUsersWithAccessIds = draftUsersWithAccess.map((item: DraftUserType) => item.userId);
    const removedUserIds = userGrants
      .filter((usr) => !draftUsersWithAccessIds.includes(usr.user.id))
      .map((u) => u.user.id);

    const shareData = {
      modelId: whatToShareId,
      removedUserIds,
      grants: draftUsersWithAccess
        .filter((u: DraftUserType) => u.subId !== currentUserSubId)
        .map((item: DraftUserType) => ({ user_id: item.userId, grant: item.grant })),
    };

    submitGivenAccessUsers.mutate(shareData, {
      onSuccess: () => {
        setDraftUsersWithAccess([]);
        onClose();
        showToast("File has been shared successfully.", "success");
        reloadModels();
      },
      onError: () => {
        showToast("An error occurred while sharing the file.", "error");
      },
    });
  }, [currentUserSubId, draftUsersWithAccess]);

  const renderUsersWithAccess = useMemo(() => {
    const data = draftUsersWithAccess?.length ? draftUsersWithAccess : usersWithAccess;
    const currentUserGrant = userGrants.find((usr) => usr.user.sub === currentUserSubId)?.grant;

    return data?.map((user) => (
      <Stack
        key={user.userId}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          px: "12px",
          py: "6px",
          borderTop: `1px solid ${customColors.gray[100]}`,
          "&:first-of-type": {
            borderTop: "none",
          },
        }}
      >
        <UserCell name={user.fullName} />

        <Box
          sx={{ display: "flex", width: "28%", alignItems: "center", justifyContent: "flex-end", position: "relative" }}
        >
          {user.subId === currentUserSubId ||
          (currentUserGrant === UserGrantType.editor && user.grant === UserGrantType.owner) ? (
            <Typography variant="subtitle3" sx={{ textTransform: "capitalize" }}>
              {user.grant}
            </Typography>
          ) : (
            <AccessMenu
              currentUserAccess={currentUserGrant as string}
              defaultUserAccess={user.grant}
              onChangeAccess={(permissionId) => {
                handleUsersGivenAccess(user.userId, permissionId as UserGrantType);
              }}
              onRemoveUserAccess={(event) => {
                handleRemoveUserAccess(event, user.userId);
              }}
            />
          )}
        </Box>
      </Stack>
    ));
  }, [
    currentUserSubId,
    draftUsersWithAccess,
    userGrants,
    usersWithAccess,
    handleUsersGivenAccess,
    handleRemoveUserAccess,
  ]);

  return (
    <Dialog
      data-testid={testId}
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        elevation: 4,
        sx: {
          padding: "20px 24px",
          maxWidth: "sm",
          maxHeight: "640px",
          margin: 0,
        },
      }}
      sx={{
        paddingX: "18px",
        "& .MuiBackdrop-root": {
          bgcolor: "",
        },
      }}
    >
      <Stack mb="20px">
        <Typography variant="h6" sx={{ color: customColors.gray[800] }}>
          {title}
        </Typography>
      </Stack>
      <Stack py="8px" rowGap="12px" mb="100px">
        <Stack direction="row" columnGap="4px">
          <Autocomplete
            id="users-list"
            fullWidth
            multiple
            disableClearable
            disableCloseOnSelect
            forcePopupIcon={false}
            sx={{
              "& .MuiOutlinedInput-root": {
                padding: 0,

                "&:hover fieldset": {
                  borderColor: customColors.primary[200],
                },

                "& .MuiAutocomplete-input": {
                  padding: "6px 9px",
                  height: "auto",
                  color: customColors.gray[900],
                  ...theme.typography.subtitle3,

                  "&::placeholder": {
                    color: customColors.gray[300],
                    ...theme.typography.subtitle3,
                  },
                },
              },
              "& .MuiAutocomplete-tag": {
                margin: "4px 6px",
              },
            }}
            componentsProps={{
              paper: {
                elevation: 3,
              },
              popper: {
                sx: {
                  "& .MuiAutocomplete-listbox": {
                    "& .MuiAutocomplete-option": {
                      "&[aria-selected=true]": {
                        bgcolor: customColors.primary[50],
                      },

                      "&[aria-disabled=true]": {
                        opacity: 1,
                        "& .MuiFormControlLabel-root": {
                          opacity: 0.4,
                        },
                      },

                      "&:hover, &.Mui-focused": {
                        bgcolor: customColors.gray[50],
                      },
                    },
                  },
                },
              },
            }}
            loading={isLoadingOrgUsers}
            options={listOfUsersWithNoAccess || []}
            noOptionsText={t("modals.manageAccess.search.noResult")}
            getOptionLabel={(user) => user.fullName}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => value.email === option.email}
            renderOption={(liProps, user) => (
              <li {...liProps} key={user.userId}>
                <Stack direction="row" columnGap="8px" alignItems="center">
                  <UserCell name={user.fullName} />
                </Stack>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t("modals.manageAccess.search.placeholder")}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: `1px solid ${customColors.gray[200]}`,
                    },
                    "&:hover fieldset": {
                      border: `1px solid ${customColors.gray[300]}`,
                    },
                    "&:focus fieldset": {
                      border: `1px solid ${customColors.gray[300]}`,
                    },

                    "&.Mui-focused fieldset": {
                      borderWidth: "1px",
                    },
                  },
                }}
              />
            )}
            onInputChange={() => {}}
            limitTags={2}
            renderTags={(value, getTagProps) =>
              value.map((user, index) => <IstariChip label={user.fullName} size="h-24" {...getTagProps({ index })} />)
            }
            onChange={handleSelectedUsers}
            value={selectedUsers}
          />

          <IstariButton
            data-testid="share-popup-add-users-list-button"
            size="h-32"
            variant="outlined"
            onClick={handleAddUsers}
            sx={{ minWidth: "80px", zIndex: 99999 }}
            disabled={selectedUsers.length === 0}
          >
            {t("common.add")}
          </IstariButton>
        </Stack>

        <Stack direction="row" columnGap="8px" sx={{ color: customColors.gray[700], alignItems: "center" }}>
          <SvgIcon
            component={Lock}
            inheritViewBox
            sx={{ fill: "none", color: customColors.gray[400], fontSize: "1.25rem" }}
          />

          <Typography variant="subtitle3" sx={{ color: "inherit" }}>
            {t("modals.manageAccess.helperText")}
          </Typography>
        </Stack>

        <Stack sx={{ minHeight: "288px" }}>
          <Stack
            sx={{
              border: `1px solid ${customColors.gray[100]}`,
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {renderUsersWithAccess}
          </Stack>
        </Stack>
      </Stack>

      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item xs={5} display="flex" justifyContent="flex-end" columnGap="8px">
          <IstariButton size="h-40" variant="lightBg" color="gray" onClick={cancelHandler} fullWidth>
            {t("common.cancel")}
          </IstariButton>

          <IstariButton
            data-testid="share-popup-save-button"
            size="h-40"
            onClick={handleSaveGivenAccessUsers}
            fullWidth
            loading={isSharing}
            loadingMessage={t("common.saving")}
            disabled={isSharing || !draftUsersWithAccess}
          >
            {t("common.save")}
          </IstariButton>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ManageAccessPopup;
