import { InputBase, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { customColors } from "../../../../lib/utils/colors";
import { theme } from "../../../../lib/utils/create-theme";

interface NameInputProps {
  value: string;
  onChange: (newValue: string) => void;
  readOnly?: boolean;
}

const NameInput: React.FC<NameInputProps> = ({ value, onChange, readOnly }) => {
  const { t } = useTranslation();

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const handleOnBlur = React.useCallback(() => {
    if (!value.trim()) {
      onChange(t("common.untitledDoc"));
    }
  }, [onChange, t, value]);

  return (
    <Tooltip
      title={t("common.rename")}
      disableFocusListener
      disableTouchListener
      disableHoverListener={readOnly}
      followCursor
    >
      <InputBase
        inputProps={{ "data-testid": "md-name-input" }}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
        onFocus={(e) => e.target.select()}
        onBlur={handleOnBlur}
        sx={{
          ...theme.typography.h6,
          borderRadius: "4px",
          outline: `1px solid transparent`,
          transition: "outline-color 0.25s ease",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 0,

          "&:hover, &.Mui-focused": {
            outlineColor: readOnly ? "transparent" : customColors.gray[300],
          },

          "& .MuiInputBase-input": {
            padding: "2px 6px",
          },
        }}
      />
    </Tooltip>
  );
};

export default NameInput;
