import { Grid, LinearProgress, Stack, SvgIcon, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IstariLogoColor } from "../../../assets/svg";
import IstariLink from "../../../components/IstariLink";

const RedirectPage = ({ redirectUrl = "" }: { redirectUrl?: string }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(redirectUrl);
    window.location.reload();
  }, [navigate, redirectUrl]);

  const getRedirectPageContent = () => {
    if (redirectUrl) {
      return (
        <>
          <LinearProgress />
          <Typography variant="h5">Redirecting</Typography>
        </>
      );
    }
    return (
      <a href="/">
        <IstariLink label="Go back to home page" />
      </a>
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
      <Stack textAlign="center" spacing="20px">
        <SvgIcon
          data-testid="redirect-page-logo"
          component={IstariLogoColor}
          inheritViewBox
          sx={{ width: "auto", height: "auto" }}
        />
        {getRedirectPageContent()}
      </Stack>
    </Grid>
  );
};

export default RedirectPage;
