import { styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import React from "react";

import { customColors } from "../../lib/utils/colors";

/**
 * @description: Styled MUI Accordion component
 */
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  () => ({
    borderBottom: `1px solid ${customColors.gray[200]}`,
    borderRadius: "none",

    "&.Mui-expanded": {
      borderBottom: "none",
    },

    "&:last-of-type": {},
    "&:before": {
      display: "none",
    },
  }),
);

/**
 * @description: Styled MUI AccordionSummary component
 */
export const IstariAccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
  () => ({
    padding: 0,
    flexDirection: "row-reverse",
    columnGap: "4px",

    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: "10px 0",
    },

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  }),
);

/**
 * @description: Styled MUI AccordionDetails component
 */
export const IstariAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  borderTop: `1px solid ${customColors.gray[100]}`,
  borderBottom: `1px solid ${customColors.gray[100]}`,
}));

interface IstariAccordionProps extends AccordionProps {
  borderColor?: string;
  borderRadius?: string;
}
/**
 *  @description: Customized MUI Accordion component
 */
const IstariAccordion: React.FC<IstariAccordionProps> = ({
  sx = [],
  borderColor = customColors.gray[100],
  borderRadius = "4px",
  children,
  ...others
}) => (
  <Accordion
    {...others}
    sx={[
      ...(Array.isArray(sx) ? sx : [sx]),
      {
        borderColor,
        "&:first-of-type": {},
        "&:last-of-type": {
          borderBottom: "none",
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius,

          "& .MuiAccordionDetails-root": {
            borderBottom: "none",
          },
        },
      },
    ]}
  >
    {children}
  </Accordion>
);

export default IstariAccordion;
