import axios, { InternalAxiosRequestConfig } from "axios";

import AuthenticationStore from "../auth";
import { HttpStatusCodeType } from "../enums";
import { refreshAccessToken } from "../external-api/auth";

// ServiceConfig as AxiosRequestConfig.config
interface ServiceConfig {
  url?: string;
}

/**
 * Creates a service abstraction around the AxiosInstance.  It currently only exposes common axios HTTP methods, but this can be expanded in the future.
 * @param {*} configuration: simple object containing just the URL.  Can be made more configurable in the future.
 */
export default function createService(configuration?: ServiceConfig) {
  const instance = axios.create({
    baseURL: configuration?.url,
    timeout: 120000,
    withCredentials: true,
  });

  const isAccessTokenExpired = () => {
    const AS = AuthenticationStore();
    const tokenExpTimestamp = AS.getAccessTokenExpires();

    if (!tokenExpTimestamp) {
      return false;
    }

    const now = Date.now();
    const expiration = tokenExpTimestamp * 1000;

    if (now > expiration) {
      return true;
    }

    return false;
  };

  instance.interceptors.request.use(async (config) => {
    if (!config.withCredentials) {
      return config as InternalAxiosRequestConfig;
    }
    const AS = AuthenticationStore();
    const accessToken = AS.getAccessToken();

    if (isAccessTokenExpired()) {
      await refreshAccessToken();
    }

    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : "Bearer";

    return config as InternalAxiosRequestConfig;
  });

  instance.interceptors.response.use(
    (response) => response?.data,

    (error) => {
      if (error.response?.status === HttpStatusCodeType.Unauthorized) {
        const AS = AuthenticationStore();
        AS.clearSessionToken();
        window.location.reload();
      }
      return Promise.reject(error);
    },
  );

  return {
    concurrentRequests: axios.all,
    get: instance.get,
    patch: instance.patch,
    post: instance.post,
    put: instance.put,
    remove: instance.delete,
  };
}
