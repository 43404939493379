import { OutputData as EditorJsOutputData } from "@editorjs/editorjs";
import { useMutation, useQuery } from "@tanstack/react-query";

import AuthenticationStore from "../auth/authenticationStore";
import { CheckInCheckOutMagicDocTypes, ModelType } from "../enums";
import { generateUniqueId } from "../lib/helpers/general-helper";
import { fileService, s3Service } from "../services";
import { sanitizeData } from "../utils/sanitize";
import { queryClient } from "./queryClient";
import { magicDocsQueryKeys } from "./queryKeys";
import { ListOptions, UserGrant } from "./useModels";
import { confirmModelArtifactUpload, informModelArtifactUploadFailed } from "./useUpload";

/**
 * Get the total count of the user's Magic Docs
 */
export const useMagicDocsCount = () =>
  useQuery(
    magicDocsQueryKeys.all,
    () =>
      fileService.get(`/model/`, {
        params: {
          model_type: ModelType.MAGIC_DOC,
        },
      }),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.total,
    },
  );

/**
 * Get List of Magic Docs
 */
export const useListMagicDocs = (filter: ListOptions) => {
  const AS = AuthenticationStore();
  const { userId: currentUserSub } = AS.getCurrentUser();
  return useQuery(
    magicDocsQueryKeys.list(filter),
    () =>
      fileService.get(`/model/`, {
        params: {
          ...filter,
          model_type: ModelType.MAGIC_DOC,
        },
      }),
    {
      refetchOnWindowFocus: false,
      select: (data) => ({
        ...data,
        list: data.items?.map((item: any) => ({
          id: item.id,
          type: item.model_type,
          name: item.name,
          owner: {
            name: `${item.creator?.given_name}${item.creator?.family_name}`,
            avatar: item.creator?.avatar || "",
          },
          date: item.updated_at,
          new: !!item.new,
          accessCount: item.user_grants?.length || 0,
          infosec_Level: item.info_sec_level || 1,
          currentUserGrant: item.user_grants?.find((ug: UserGrant) => ug.user.sub === currentUserSub)?.grant,
          user_grants: item.user_grants,
        })),
      }),
    },
  );
};

/**
 * Post/Create a new Magic Doc
 */
export const useCreateMagicDoc = () =>
  useMutation(async (props: { name: string; newDocJson: EditorJsOutputData }) => {
    //   The starting JSON of the doc editor for creating the new magic doc
    const newDocStartingJson = props.newDocJson || {
      blocks: [
        { id: generateUniqueId(), type: "header", data: { text: props.name, level: 2 } },
        {
          id: generateUniqueId(),
          type: "magicLink",
        },
      ],
    };

    // 1- Create a new model artifact for the new magic doc
    const modelArtifact = await fileService.post("/model/", {
      name: props.name,
      model_type: ModelType.MAGIC_DOC,
    });

    if (modelArtifact.base_artifact) {
      // 2- Upload the starting JSON to S3
      return s3Service
        .put(modelArtifact.base_artifact?.signed_upload_url, newDocStartingJson, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          withCredentials: false,
        })
        .then(async () => {
          // 3- Confirm the upload
          await confirmModelArtifactUpload(modelArtifact.base_artifact?.model_id, modelArtifact.base_artifact?.id);

          return modelArtifact;
        })
        .catch(async (error) => {
          await informModelArtifactUploadFailed(modelArtifact.base_artifact?.model_id, modelArtifact.base_artifact?.id);
          return { data: modelArtifact, errorToS3: true, error };
        });
    }
    return modelArtifact;
  });

/**
 * Get a Magic Doc by ID --> Get the JSON of the doc from S3
 *
 * returns the JSON content cached in the local storage if it's newer than the fetched content
 */
export const useGetMagicDoc = (id: string, queryOptions: { enabled?: boolean }) =>
  useQuery(
    magicDocsQueryKeys.item(id),
    () =>
      fileService.get(`/model/${id}`).then(async (res) => {
        if (!res.base_artifact?.signed_download_url)
          return {
            data: res,
            json: null,
          };
        const response = await s3Service.get(res.base_artifact.signed_download_url, {
          withCredentials: false,
          transformResponse: [
            function (data: any) {
              return JSON.parse(sanitizeData(data) as string);
            },
          ],
        });

        let docContent;
        // Check if the doc content is cached in the local storage
        const cachedDocContent = localStorage.getItem(id);

        // If the cached content is newer than the fetched content, use the cached content
        if (cachedDocContent) {
          const parsed = JSON.parse(cachedDocContent);
          docContent = parsed?.time > response.data?.time ? parsed : response;
        } else {
          docContent = response;
        }
        // return the doc JSON content and the fetched MD data
        return { data: res, json: docContent };
      }),
    {
      refetchOnWindowFocus: false,
      enabled: queryOptions.enabled,
      // to make sure the cache is always fresh
      staleTime: 0,
      cacheTime: 0,
    },
  );

/**
 * Save the changes of the Magic Doc / Create new version
 */
export const useSaveMagicDoc = () =>
  useMutation(async (props: { name: string; id: string; newDocJson: EditorJsOutputData; currVersionId: string }) => {
    // 1- Create a new base artifact
    const modelArtifact = await fileService.post(`/model/${props.id}/create_version`, {
      file_version: props.newDocJson?.version || "1.0.0",
      name: `${props.name}.${props.newDocJson?.version}`,
      artifact_extension: "json",
    });

    if (modelArtifact) {
      // 2- Upload the starting JSON to S3
      return s3Service
        .put(modelArtifact.signed_upload_url, props.newDocJson, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          withCredentials: false,
        })
        .then(async () => {
          // 3- Confirm the upload
          await confirmModelArtifactUpload(modelArtifact.model_id, modelArtifact.id);

          return modelArtifact;
        })
        .catch(async (err) => {
          // revert the changes
          await fileService.post(`/model/${props.id}/revert_to_version/${props.currVersionId}`);

          return { data: modelArtifact, errorToS3: true, error: err };
        });
    }
    return modelArtifact;
  });

/**
 * Get Magic Doc Derived Artifact
 */

export const useGetMagicDocDerivedArtifact = (
  props: {
    modelId: string;
    artifactId: string;
  },
  queryOptions: { enabled: boolean },
) => {
  const { modelId, artifactId } = props;
  return useQuery(
    magicDocsQueryKeys.item(`model_id:${modelId}/artifact_id:${artifactId}`),
    () => fileService.get(`/model/${modelId}/artifact:MagicDoc/${artifactId}`),
    {
      refetchOnWindowFocus: false,
      enabled: queryOptions.enabled,
    },
  );
};

/**
 * Check-in|Check-out a Magic Doc
 */
export const useCheckInCheckOutMagicDoc = () =>
  useMutation((data: { modelId: string; modelName: string; action: CheckInCheckOutMagicDocTypes }) =>
    fileService
      .patch(`/model/${data.modelId}`, {
        check_in_check_out: data.action,
        name: data.modelName,
      })
      .then(async (res) => {
        // invalidate the query to refetch the data
        await queryClient.invalidateQueries(magicDocsQueryKeys.item(data.modelId));

        return res;
      }),
  );
