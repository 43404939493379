import { Avatar, Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/system";
import React, { CSSProperties, useMemo } from "react";
import { X as XIcon } from "react-feather";

import { customColors, CustomColorsType } from "../../lib/utils/colors";
import { theme } from "../../lib/utils/create-theme";

const SIZES = ["h-20", "h-24", "h-32"] as const;
const STYLES = ["normal", "hover", "pressed", "disabled"] as const;
const EXCLUDED_PROPS = ["color", "label", "size", "variant"] as const;

interface StyledChipProps extends ChipProps {
  height: (typeof SIZES)[number];
  styles: Record<(typeof STYLES)[number], CSSProperties>;
}
const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !["color", "size"].includes(prop as never),
})((props: StyledChipProps) => ({
  height: `${props.height.split("-")[1]}px`,
  borderWidth: "1px",
  borderStyle: "solid",
  cursor: props.clickable ? "pointer" : "default",
  ...props.styles.normal,
  "&:hover": {
    ...props.styles.hover,
  },
  "&.Mui-disabled": {
    ...props.styles.disabled,
    opacity: "0.4",
  },
  "&:active": {
    ...props.styles.pressed,
  },
}));

const CHIP_VARIANTS = ["filled", "outlined"] as const;

const TYPO_VARIANTS = {
  "h-32": "subtitle2",
  "h-24": "subtitle4",
  "h-20": "subtitle4",
} as const;

const ICON_SIZE = {
  "h-32": "20px",
  "h-24": "16px",
  "h-20": "16px",
} as const;

const AVATAR_SIZE = {
  "h-32": "24px",
  "h-24": "20px",
  "h-20": "16px",
} as const;

const PADDING_X = {
  "h-32": "8px",
  "h-24": "8px",
  "h-20": "4px",
} as const;

const AVATAR_MARGIN_LEFT = {
  "h-32": "-5px",
  "h-24": "-7px",
  "h-20": "-3px",
} as const;

type ExcludedProps = (typeof EXCLUDED_PROPS)[number];
interface IstariChipProps extends Omit<ChipProps, ExcludedProps> {
  color?: keyof CustomColorsType;
  variant?: (typeof CHIP_VARIANTS)[number];
  label?: string;
  size?: (typeof SIZES)[number];
  avatarSrc?: string;
}
const IstariChip: React.FC<IstariChipProps> = ({
  color = "primary",
  size = "h-24",
  variant = "filled",
  label,
  sx = [],
  avatarSrc,
  clickable = true,
  ...others
}) => {
  const styles = useMemo(
    () => ({
      filled: {
        normal: {
          backgroundColor: customColors[color][color === "gray" ? 200 : 400],
          borderColor: customColors[color][color === "gray" ? 300 : 500],
          color: color === "gray" ? customColors.gray[800] : customColors.white[0],
        },
        hover: {
          backgroundColor: customColors[color][color === "gray" ? 100 : 300],
          borderColor: customColors[color][color === "gray" ? 300 : 400],
          color: color === "gray" ? customColors.gray[600] : customColors.white[0],
        },
        pressed: {
          backgroundColor: customColors[color][color === "gray" ? 200 : 400],
          borderColor: customColors[color][color === "gray" ? 200 : 400],
          color: color === "gray" ? customColors.gray[800] : customColors.white[0],
        },
        disabled: {
          backgroundColor: customColors[color][color === "gray" ? 200 : 400],
          borderColor: customColors[color][color === "gray" ? 300 : 500],
          color: color === "gray" ? customColors.gray[800] : customColors.white[0],
          opacity: 0.4,
        },
      },

      outlined: {
        normal: {
          backgroundColor: "transparent",
          borderColor: customColors[color][color === "gray" ? 300 : 400],
          color: color === "gray" ? customColors.gray[800] : customColors[color][400],
        },
        hover: {
          backgroundColor: customColors[color][50],
          borderColor: customColors[color][300],
          color: color === "gray" ? customColors.gray[600] : customColors[color][300],
        },
        pressed: {
          backgroundColor: customColors[color][50],
          borderColor: customColors[color][color === "gray" ? 300 : 400],
          color: color === "gray" ? customColors.gray[800] : customColors[color][400],
        },
        disabled: {
          backgroundColor: "transparent",
          borderColor: customColors[color][color === "gray" ? 300 : 400],
          color: color === "gray" ? customColors.gray[800] : customColors[color][400],
          opacity: 0.4,
        },
      },
    }),
    [color],
  );
  return (
    <StyledChip
      height={size}
      styles={styles[variant]}
      label={label}
      clickable={clickable}
      deleteIcon={<XIcon size={ICON_SIZE[size]} style={{ color: "inherit" }} />}
      avatar={avatarSrc ? <Avatar src={avatarSrc} /> : undefined}
      {...others}
      sx={[
        {
          paddingX: PADDING_X[size],
          // columnGap: COLUMN_GAP[size],

          "& .MuiChip-avatar": {
            width: AVATAR_SIZE[size],
            height: AVATAR_SIZE[size],
            margin: 0,
            marginLeft: AVATAR_MARGIN_LEFT[size],
          },

          "& .MuiChip-label": {
            padding: 0,
            paddingX: "4px",
            ...theme.typography[TYPO_VARIANTS[size]],
          },

          "& .MuiChip-deleteIcon": {
            margin: 0,
            bgcolor: "transparent",
            borderRadius: "50%",
            transition: "all 0.3s",

            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.1)",
            },
          },

          "& .MuiChip-icon": {
            fontSize: ICON_SIZE[size],
            color: "inherit",
            margin: 0,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default IstariChip;
