import {
  FileCad,
  FileCameo,
  FileDefault,
  FileDoc,
  FileJpg,
  FileJson,
  FileMatlab,
  FilePng,
  FileXls,
  FileXml,
} from "../assets/svg";

export const getFileNameWithoutExtension = (fileName: string, fileExtension: string): string => {
  const extIndex = fileName.toLowerCase().lastIndexOf(`.${fileExtension.toLowerCase()}`);
  if (extIndex === -1) {
    return fileName;
  }
  const trimInd = fileName.lastIndexOf(".") ?? fileName.length;
  return fileName.substring(0, trimInd);
};

export const getLiteralModelName = (extension: string): string => {
  switch (extension) {
    case "catpart":
    case "catproduct":
      return "Catia";
    case "mdzip":
      return "Cameo";
    case "xlsx":
      return "Excel";
    default:
      return extension;
  }
};

export const getExtractableFileType = (extension: string): string => {
  switch (extension) {
    case "catpart":
    case "catproduct":
      return "catia";
    case "mdzip":
      return "cameo";
    case "xlsx":
    case "csv":
      return "excel";
    default:
      return extension;
  }
};

export const getFileSVGIconByFileType = (fileType: string): React.ElementType => {
  switch (fileType) {
    case "catia":
      return FileCad;
    case "cameo":
      return FileCameo;
    case "excel":
      return FileXls;
    case "jpg":
      return FileJpg;
    case "json":
      return FileJson;
    case "matlab":
      return FileMatlab;
    case "png":
      return FilePng;
    case "xml":
      return FileXml;
    case "word":
      return FileDoc;

    default:
      return FileDefault;
  }
};
