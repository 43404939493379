import { SxProps, Theme } from "@mui/material";
import { TypographyOptions, Variant } from "@mui/material/styles/createTypography";

import { customColors } from "../../../../../lib/utils/colors";
import { theme } from "../../../../../lib/utils/create-theme";

const createImportantStyles = (styles: { [key: string]: React.CSSProperties[keyof React.CSSProperties] }) =>
  Object.keys(styles).reduce(
    (acc, key) => ({ ...acc, [key]: `${styles[key]} !important` }),
    {} as { [key: string]: string },
  );

/**
 * Getting the styles for the headers from the our MUI custom/created theme
 */
const HEADERS_STYLES = Object.keys(theme.typography)
  .filter((key) => key.startsWith("h"))
  .reduce(
    (acc, header) => ({
      ...acc,
      [header]: { ...theme.typography[header as keyof TypographyOptions & Variant], color: customColors.gray[800] },
    }),
    {} as Record<string, React.CSSProperties>,
  );

export const EDITOR_JS_STYLES: SxProps<Theme> = {
  ".codex-editor": {
    flexGrow: 1,
    maxWidth: "100%",

    "& .codex-editor__redactor": {
      // HERE IS THE STYLES FOR THE BLOCKS CONTAINER
      margin: 0,

      /**
       * STYLING THE BLOCKS
       */
      "& .ce-block": {
        "& .ce-block__content": {
          maxWidth: "none",
          ...theme.typography.body3,
          color: customColors.gray[800],

          // STYLING THE HEADERS
          ...HEADERS_STYLES,
          "& .ce-header": {
            padding: "16px 8px 8px 8px",
          },

          // STYLING THE PARAGRAPHS
          "& .ce-paragraph": {
            color: customColors.gray[700],
            ...theme.typography.body3,
            padding: "8px",
          },
        },

        // Styling the first header block / Title
        "&:first-of-type": {
          "& .ce-block__content": {
            "& .ce-header": {
              padding: "8px 8px 24px 8px",
            },
          },
        },
        //   --------------------------------------------
      },
    },

    /**
     * STYLING THE INLINE TOOLBAR
     */
    "& .ce-inline-toolbar": {
      borderRadius: "4px",

      "& .ce-inline-toolbar__toggler-and-button-wrapper": {
        color: customColors.gray[800],

        "& .ce-inline-toolbar__dropdown": {
          padding: "2px 6px",
        },

        "& .ce-inline-toolbar__buttons": {
          "& .ce-inline-tool": {
            padding: "2px",
          },
        },
      },
      //   -----------------------------
    },

    /**
     * STYLING THE TOOLBAR
     */
    "& .ce-toolbar": {
      "& .ce-toolbar__content": {
        maxWidth: "none",

        "& .ce-toolbar__actions": {
          right: "unset",
          left: "-48px",
          top: "4px",
          paddingRight: "4px",

          "& .ce-toolbox": {
            "& .ce-popover": {
              right: "unset",
              left: 0,
            },
          },
          "& .ce-settings": {
            "& .ce-popover": {
              right: "unset",
              left: "50%",
            },
          },

          "& .ce-toolbar__plus, & .ce-toolbar__settings-btn": {
            color: customColors.gray[800],
            width: "20px",
            height: "20px",
            borderRadius: "4px",

            "&:hover": {
              backgroundColor: customColors.gray[100],
            },
          },

          "& .ce-toolbar__settings-btn": {
            marginLeft: "4px",
          },
        },
      },
    },
    //   --------------------------------------------

    /**
     * Styling the Blocks
     */
    "& .ce-block": {
      "& .ce-block__content": {
        /**
         * Styling the Magic Link plugin
         */
        "& .link-tool": {
          // Styling the rendered content
          "& .link-tool__content--rendered": {
            backgroundColor: customColors.primary[50],
            padding: "8px",
            boxShadow: "none",
            border: "none",
            borderRadius: "4px",

            "& .magicLink-image-container": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& img": {
                maxHeight: "100%",
                width: "auto",
              },
            },

            "& .magicLink-json-container": {
              "& .json-formatter-row": {
                overflow: "auto",

                "&::-webkit-scrollbar": {
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: customColors.gray[400],
                  borderRadius: "4px",
                },
              },
            },
          },

          "& .link-tool__input-holder": {
            // Styling the input
            "& .link-tool__input": {
              color: customColors.gray[700],
              ...theme.typography.body3,
              padding: "8px",
              backgroundImage: "none",
              backgroundColor: customColors.primary[50],
              border: "none",
              boxShadow: "none",
              borderRadius: "4px",
              // style the  data placeholder
              '&[contenteditable="true"]:empty:before': {
                color: customColors.gray[700],
                opacity: 0.5,
              },
            },

            "&--error": {
              "& .link-tool__input": {
                backgroundColor: customColors.red[100],
                color: customColors.red[400],
              },
            },
          },

          // Styling the tags & info container
          "& .info-and-tags-container": {
            ...createImportantStyles({
              borderTop: "none",
              borderRadius: "4px",
              padding: "8px",
              marginTop: "8px",
              alignItems: "flex-start",
              backgroundColor: customColors.gray[50],
            }),

            "& .artifact-info-text": {
              color: customColors.gray[800],
              ...theme.typography.subtitle4,
            },

            "& .tags": {
              gap: "4px !important",
              alignItems: "center",
              "& .tag": {
                ...createImportantStyles({
                  backgroundColor: customColors.green[400],
                  ...theme.typography.subtitle4,
                  fontWeight: 400,
                  boxShadow: "none",
                  borderRadius: "4px",
                  padding: "2px 4px",
                  textWrap: "nowrap",
                }),
              },
            },
          },

          // Styling the Collapse button
          "& .collapse-button": {
            // backgroundColor: "transparent",
            backgroundColor: customColors.gray[50],
            color: customColors.gray[800],
            padding: 0,
            marginTop: "4px",
            marginBottom: "8px",

            "& svg": {
              fill: "none",
            },

            "&:hover": {
              backgroundColor: customColors.gray[100],
            },
          },

          "& .link-tool__progress": {
            backgroundColor: customColors.primary[400],
            opacity: 0.2,
            "&.link-tool__progress--loaded": {
              backgroundColor: customColors.secondary[400],
            },
          },

          "& .link-tool__content-loading-holder": {
            backgroundColor: customColors.primary[50],

            "& .artifact-url-text": {
              color: customColors.gray[700],
              ...theme.typography.subtitle4,
            },
          },

          "& .link-tool__failed-fetching-content-holder": {
            backgroundColor: customColors.red[100],
            rowGap: "2px",

            "& .failed-content__warning-icon": {
              width: "32px",
              height: "32px",
              svg: {
                color: customColors.red[300],
                height: "100%",
                width: "100%",
              },
            },

            "& .failed-content__error-message": {
              color: customColors.red[300],
              ...theme.typography.subtitle4b,
              margin: 0,
              whiteSpace: "pre-line",
              textAlign: "center",
            },
          },
        },

        //   --------------------------------------------

        // Make sure the text-containing blocks are responsive when readonly is on
        "& .ce-header, .cdx-block": {
          overflowWrap: "break-word",
          WebkitLineBreak: "after-white-space",
        },
      },
    },
  },

  // Media Print styles
  "@media print": {
    "& .ce-toolbar, & .ce-inline-toolbar": {
      display: "none",
    },
  },
};
