import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { memo, MouseEvent, useCallback, useState } from "react";

import { UserGrantType } from "../../enums";
import { accessOptions } from "../../permissions";
import IstariLink from "../IstariLink";
import Submenu from "../Submenu";

interface AccessMenuProps {
  currentUserAccess: string;
  defaultUserAccess: string | undefined;
  onChangeAccess: (_value: string) => void;
  onRemoveUserAccess: (_e: MouseEvent<HTMLElement>) => void;
}

const AccessMenu = memo(
  ({ currentUserAccess, defaultUserAccess = "", onChangeAccess, onRemoveUserAccess }: AccessMenuProps) => {
    const [selectedAccess, setSelectedAccess] = useState<string>(defaultUserAccess);
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

    const getAccessBasedOnRole = (access: string) => {
      // approver or viewer will not see the model share option
      if (access === UserGrantType.editor) {
        return accessOptions.filter((permission) => permission.name !== UserGrantType.owner);
      }
      return accessOptions;
    };

    const permissionOptions = getAccessBasedOnRole(currentUserAccess);

    const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget as HTMLInputElement);
    }, []);

    const handleMenuClose = useCallback((event: MouseEvent<HTMLElement>) => {
      event.preventDefault();

      setAnchorEl(null);
    }, []);

    const itemClickHandler = useCallback(
      (event: MouseEvent<HTMLElement>, itemName: string) => {
        event.preventDefault();
        const itemId = permissionOptions.filter((item) => item.name === itemName)[0]?.name;
        onChangeAccess(itemId);
        setSelectedAccess(itemId);
        setAnchorEl(null);
      },
      [onChangeAccess, permissionOptions],
    );

    return (
      <>
        <IstariLink
          data-testid="access-menu-trigger"
          color="primary"
          disabled={false}
          endIcon={ArrowDownIcon}
          label={selectedAccess}
          sx={{ textTransform: "capitalize" }}
          size={14}
          startIcon={undefined}
          underline={false}
          onClick={handleMenuOpen}
        />
        <Submenu
          size="h-40"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          selectedItem={selectedAccess}
          clickHandler={itemClickHandler}
          items={permissionOptions}
          handleClose={handleMenuClose}
          specialOptionProps={{
            label: "Remove",
            onClick: onRemoveUserAccess,
          }}
        />
      </>
    );
  },
);

export default AccessMenu;
