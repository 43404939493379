import Delimiter from "@editorjs/delimiter";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import EditorJsTable from "@editorjs/table";
import IstariMagicLink from "@IstariMagicLink/index";

import AuthenticationStore from "../../../../../auth/authenticationStore";
import { s3Service } from "../../../../../services";
import { getEnv } from "../../../../../utils/get_env";
import { sanitizeData } from "../../../../../utils/sanitize";
import TestIdTune from "./TestIdTune";

const AS = AuthenticationStore();
const accessToken = AS.getAccessToken();

export const EDITOR_JS_TOOLS = {
  header: {
    class: Header,
    inlineToolbar: true,
    config: {
      placeholder: "Type a header",
    },
  },
  delimiter: Delimiter,
  list: List,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: "Type something",
    },
  },
  magicLink: {
    class: IstariMagicLink,
    config: {
      endpoint: `${getEnv("VITE_FILE_AUTH_ENDPOINT")}`,
      accessToken,
      s3AxiosInstance: s3Service,
      sanitizer: sanitizeData,
    },
  },
  table: EditorJsTable,
  testIdTune: TestIdTune,
};
