import { OutputBlockData } from "@editorjs/editorjs";
import { ButtonBase, Stack, Tooltip, Typography } from "@mui/material";
import he from "he";
import React from "react";

import { customColors } from "../../../../lib/utils/colors";

const MAX_OUTLINE_ITEM_LENGTH = 27;

/**
 * Calculate the indentation level for each header block (outline item):
 * - The indentation level is the number of parents for each header
 * @param list array of headers blocks
 * @returns  an array of indentation levels for each header (outline item)
 */
const getIndentation = (list: OutputBlockData[]) => {
  const parent: number[] = [];
  const indentation: number[] = [];

  function calcIndentation(headIdx: number) {
    // If the header is a root header
    if (parent[headIdx] === -1) {
      indentation[headIdx] = 0;
      return 0;
    }
    // If the indentation level is already calculated
    if (indentation[headIdx] !== undefined) return indentation[headIdx];

    // Calculate the indentation levels
    indentation[headIdx] = 1 + calcIndentation(parent[headIdx]);

    return indentation[headIdx];
  }

  // locate the parent (its index in the headers list) of each header
  for (let i = list.length - 1; i >= 0; i--) {
    const item = list[i];
    const { level } = item.data;
    let parentIdx = -1;
    // Find the parent of the current item (first header with a lower level than the current header)
    for (let j = i - 1; j >= 0; j--) {
      if (list[j].data.level < level) {
        parentIdx = j;
        break;
      }
    }

    parent[i] = parentIdx;
  }

  // Calculate the indentation level for each header
  for (let i = list.length - 1; i >= 0; i--) {
    calcIndentation(i);
  }

  return indentation;
};

interface OutlineItemProps {
  id: string;
  title: string;
  activeId: string;
  onClick: (id: string) => void;
  indentation: number;
}
const OutlineItem: React.FC<OutlineItemProps> = (props) => (
  <Tooltip
    title={props.title}
    placement="right"
    arrow
    disableHoverListener={props.title.length <= MAX_OUTLINE_ITEM_LENGTH}
  >
    <ButtonBase
      data-testid="md-outline-item"
      key={props.id}
      className={props.id === props.activeId ? "active" : ""}
      onClick={() => props.onClick(props.id)}
      sx={{
        justifyContent: "flex-start",
        color: customColors.gray[800],
        transition: "all 0.25s ease",
        "&:hover, &.active": {
          color: customColors.primary[400],
        },

        // margin bottom except for the last item
        "&:not(:last-child)": {
          marginBottom: "8px",
        },

        marginLeft: `${props.indentation * 16}px`,
      }}
    >
      <Typography className="title" variant="subtitle3b" color="inherit" noWrap>
        {he.decode(props.title)}
      </Typography>
    </ButtonBase>
  </Tooltip>
);

const OutlineList: React.FC<{ headers: OutputBlockData[] }> = (props) => {
  const [activeOutline, setActiveOutline] = React.useState("");

  /**
   * Handler for clicking the headers items in the outline tab
   * @param headerId
   */
  const onClickOutlineItem = React.useCallback((headerId: string) => {
    // 1- SCROLL TO THE DOC HEADER
    const section = document.querySelector(`[data-id="${headerId}"]`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }

    // 2- SET THE CLICKED ITEM AS ACTIVE TO APPLY THE STYLES
    setActiveOutline(headerId);
  }, []);

  const Outlines = React.useMemo(() => {
    const indentations = getIndentation(props.headers || []);

    return props.headers?.map((header, index) => (
      <OutlineItem
        key={header.id}
        id={header.id || ""}
        activeId={activeOutline}
        title={header.data?.text || ""}
        onClick={onClickOutlineItem}
        indentation={indentations[index]}
      />
    ));
  }, [activeOutline, onClickOutlineItem, props.headers]);

  return <Stack data-testid="md-outlines">{Outlines}</Stack>;
};

export default OutlineList;
