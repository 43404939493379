import { Button, Tooltip } from "@mui/material";
import React from "react";

import { Megaphone } from "../../../../assets/svg";
import { customColors } from "../../../../lib/utils/colors";

interface HelpAndSuggestionsButtonProps {}
const HelpAndSuggestionsButton: React.FC<HelpAndSuggestionsButtonProps> = () => (
  <Tooltip title="Help and Suggestions">
    {/*
        MUI: You are providing a disabled `button` child to the Tooltip component.
        A disabled element does not fire events.
        Tooltip needs to listen to the child element's events to display the title.
      */}

    <span>
      <Button
        aria-label="Help and Suggestions"
        href="https://istari.canny.io"
        target="_blank"
        sx={{
          position: "relative",
          borderRadius: "4px",
          bgcolor: customColors.white[0],
          minWidth: "auto",
          p: 0,
          width: "40px",
          height: "40px",
        }}
      >
        {/* Help Icon */}
        <Megaphone color={customColors.gray[400]} />
      </Button>
    </span>
  </Tooltip>
);

export default HelpAndSuggestionsButton;
