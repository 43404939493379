import DOMPurify from "dompurify";

export const sanitizeData: (obj: unknown) => unknown = (obj) => {
  // Check if obj is an array
  if (Array.isArray(obj)) {
    // If it's an array, loop through each element and sanitize recursively
    return obj.map((item) => sanitizeData(item));
  }

  // Check if obj is an object
  if (typeof obj === "object" && obj !== null) {
    // If it's an object, loop through each key-value pair and sanitize recursively
    const sanitizedObj: { [key: string]: unknown } = {};
    Object.keys(obj).forEach((key) => {
      sanitizedObj[key] = sanitizeData(obj[key as keyof typeof obj]);
    });

    return sanitizedObj;
  }

  // if obj is null or undefined, return it as is
  if (obj === null || obj === undefined) return obj;

  // For other types (strings, numbers, etc.), apply the sanitizer function
  return DOMPurify.sanitize((obj as string).toString()) as string;
};
