import { ModelActionType, UserGrantType, ZitadelRoles } from "../enums";

interface MembershipList {
  roles: string[];
}

interface Membership {
  result?: MembershipList[];
}

export const checkIsCustomerAdmin = (data: Membership | undefined) =>
  data && data.result && data.result[0]?.roles?.includes(ZitadelRoles.ADMIN);

export const accessOptions = Object.values(UserGrantType).map((item) => ({
  label: item,
  name: item,
}));

export const getCanUserRemove = (permission: UserGrantType) => permission === UserGrantType.owner; // only file owner can remove the file

export const getCanUserExtract = (permission: UserGrantType) => permission === UserGrantType.owner; // only model owner can extract the model

export const getCanUserEdit = (permission: UserGrantType) =>
  permission === UserGrantType.editor || permission === UserGrantType.owner;

export const getCanUserShare = (permission: UserGrantType) =>
  permission === UserGrantType.editor || permission === UserGrantType.owner;

export const getCanUserApprove = (permission: UserGrantType) =>
  permission === UserGrantType.approver || permission === UserGrantType.editor || permission === UserGrantType.owner;

export const getCanUserViewOnly = (permission: UserGrantType) =>
  permission === UserGrantType.approver || permission === UserGrantType.viewer;

export const getCanUser = (permission: UserGrantType) => ({
  [ModelActionType.EXTRACT]: getCanUserExtract(permission),
  [ModelActionType.REMOVE]: getCanUserRemove(permission),
  [ModelActionType.RENAME]: getCanUserEdit(permission),
  [ModelActionType.SHARE]: getCanUserShare(permission),
  [ModelActionType.APPROVE]: getCanUserApprove(permission),
});
