import EditorJS, { OutputData } from "@editorjs/editorjs";
import { Box } from "@mui/material";
import DragDrop from "editorjs-drag-drop";
import React, { useCallback, useEffect, useRef } from "react";

import { EDITOR_JS_STYLES } from "./editorJs-styles";
import { EDITOR_JS_TOOLS } from "./editorJs-tools";

interface EditorJsWrapperComponentProps {
  data?: OutputData;
  onChange?: (data: OutputData) => void;
  placeholder?: string;
  autofocus?: boolean;
  readOnly?: boolean;
}

const EditorJsWrapperComponent: React.FC<EditorJsWrapperComponentProps> = (props) => {
  const editorRef = useRef<EditorJS | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  /**
   * Handle on ready
   * undo and drag drop instances
   * TODO: IMPLEMENT UNDO/REDO FUNCTIONALITY
   */
  const handleOnReady = useCallback(() => {
    setIsLoading(false);

    if (editorRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const dragDropInstance = new DragDrop(editorRef.current);
    }
  }, []);

  /**
   * Editor holder ref
   */
  const editorHolderRef = useCallback(
    (node: HTMLElement | null) => {
      if (node && !editorRef.current) {
        editorRef.current = new EditorJS({
          holder: node,
          tools: EDITOR_JS_TOOLS,
          // Apply the testIdTune to all the blocks
          tunes: ["testIdTune"],

          data: props.data,
          onChange: () => {
            if (editorRef.current && typeof editorRef.current.save === "function") {
              if (editorRef.current.readOnly.isEnabled) return;

              editorRef.current.save().then((outputData) => {
                if (props.onChange) {
                  props.onChange(outputData);
                }
              });
            }
          },
          onReady: handleOnReady,
          placeholder: props.placeholder,
          autofocus: props.autofocus,
          readOnly: props.readOnly,
        });
      }
    },
    [handleOnReady, props],
  );

  /**
   * update readOnly
   */
  useEffect(() => {
    if (!isLoading && editorRef.current && editorRef.current.readOnly) {
      editorRef.current.readOnly.toggle(props.readOnly);
    }
  }, [props.readOnly, isLoading]);

  /**
   * Cleanup
   */
  useEffect(
    () => () => {
      if (editorRef.current && typeof editorRef.current.destroy === "function") {
        editorRef.current.destroy();
      }
    },
    [],
  );

  return (
    <Box
      data-testid="editor-js-wrapper"
      sx={{
        display: "flex",
        flexGrow: 1,
        padding: "40px 80px",
        /**
         * EditorJS styles
         */
        ...EDITOR_JS_STYLES,
      }}
      ref={editorHolderRef}
    />
  );
};

export default EditorJsWrapperComponent;
