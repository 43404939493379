import moment from "moment";

export function formatDate(dateString: string, numberOnly = true, withHours = true): string {
  if (numberOnly) {
    return withHours
      ? moment.utc(dateString).local().format("YYYY-MM-DD h:mm A")
      : moment.utc(dateString).local().format("YYYY-MM-DD");
  }
  return withHours
    ? moment.utc(dateString).local().format("lll")
    : moment.utc(dateString).local().format("MMM Do YYYY, h:mm A");
}
