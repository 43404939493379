import { OutputData } from "@editorjs/editorjs";
import EastIcon from "@mui/icons-material/East";
import { Avatar, Box, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { saveAs } from "file-saver";
import React from "react";
import { Code, Download, Link, Users } from "react-feather";
import { useTranslation } from "react-i18next";
import { useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";

import { queryClient } from "../../../api/queryClient";
import { magicDocsQueryKeys } from "../../../api/queryKeys";
import {
  useCheckInCheckOutMagicDoc,
  useCreateMagicDoc,
  useGetMagicDoc,
  useSaveMagicDoc,
} from "../../../api/useMagicDocs";
import { useRenameModel, UserGrant } from "../../../api/useModels";
import { useGetOrgUserBySub } from "../../../api/useUsers";
import { MagicDocFile } from "../../../assets/svg";
import AuthenticationStore from "../../../auth/authenticationStore";
import ActionsBar from "../../../components/ActionsBar";
import { ActionButton, ACTIONS_BAR_HEIGHT, ActionsBarProps } from "../../../components/ActionsBar/ActionsBar";
import IstariLink from "../../../components/IstariLink";
import { showToast } from "../../../components/IstariToast/showToast";
import { TOP_HEADER_HEIGHT } from "../../../components/Layout/constants";
import ManageAccessPopup from "../../../components/ManageAccessPopup";
import Modal from "../../../components/Modal";
import { CheckInCheckOutMagicDocTypes, HttpStatusCodeType, RoutePathType, UserGrantType } from "../../../enums";
import { generateUniqueId } from "../../../lib/helpers/general-helper";
import { customColors } from "../../../lib/utils/colors";
import { getCanUserEdit, getCanUserShare } from "../../../permissions";
import { convertEditorJsToJupyter } from "../../../utils/convertToJupyter";
import DocSideBar from "./partials/DocSideBar";
import EditorJsWrapper from "./partials/EditorJsWrapper";
import NameInput from "./partials/NameInput";

const DRAFT_DOC_PAGE_DATA: OutputData = {
  blocks: [
    {
      type: "header",
      id: generateUniqueId(),
      data: {
        text: "New Magic Doc",
        level: 1,
      },
    },
    {
      type: "magicLink",
      id: generateUniqueId(),
      data: {},
    },
  ],
};

type ActionButtonsNames =
  | "saveBtn"
  | "createBtn"
  | "editBtn"
  | "cancelBtn"
  | "shareBtn"
  | "copyLinkBtn"
  | "downloadBtn"
  | "codeBtn";

interface DocEditorProps {
  isDraftPage?: boolean;
}
const DocEditor: React.FC<DocEditorProps> = (props) => {
  // local states
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isCheckingOut, setIsCheckingOut] = React.useState<boolean>(false);
  const [isRequestingEdit, setIsRequestingEdit] = React.useState<boolean>(false);
  const [isLocked, setIsLocked] = React.useState<boolean>(false);
  const [isLeaving, setIsLeaving] = React.useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = React.useState<boolean>(false);
  const [docDraftData, setDocDraftData] = React.useState<OutputData>({
    blocks: [],
  });
  const [docName, setDocName] = React.useState<string>("");

  // refs
  const isDirty = React.useRef(false);

  // hooks
  const navigate = useNavigate();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isDirty.current && nextLocation.pathname !== currentLocation.pathname,
  );
  const { t } = useTranslation();
  const { docId = "" } = useParams();
  const location = useLocation();
  // react-query hooks
  const { data, isInitialLoading: isLoadingJson, isError, error } = useGetMagicDoc(docId, { enabled: !!docId });
  const saveDoc = useSaveMagicDoc();
  const createNewDoc = useCreateMagicDoc();
  const checkInCheckOutDoc = useCheckInCheckOutMagicDoc();
  const renameDoc = useRenameModel();

  // permissions
  const AS = AuthenticationStore();
  const currentUser = AS.getCurrentUser();
  // If the doc is a created draft, the current user is the owner
  const currUserGrant = props.isDraftPage
    ? UserGrantType.owner
    : data?.data?.user_grants?.find((grant: UserGrant) => grant.user?.sub === currentUser.userId)?.grant;
  const canEditDoc = getCanUserEdit(currUserGrant);
  const canShareDoc = getCanUserShare(currUserGrant);
  const { data: currentOrgUser } = useGetOrgUserBySub(currentUser.userId || "");

  /**
   * Reload models handler
   */
  const reloadModelsHandler = React.useCallback(() => {
    // invalidate the docs listing
    queryClient.invalidateQueries(magicDocsQueryKeys.all);

    // invalidate the current doc
    queryClient.invalidateQueries(magicDocsQueryKeys.item(docId));
  }, [docId]);

  /**
   * On Doc change handler (Debounced)
   */
  const onDocChangeHandler = React.useCallback(
    (newData: OutputData) => {
      isDirty.current = true;
      setDocDraftData(newData);

      // save to local storage
      localStorage.setItem(docId, JSON.stringify(newData));
    },
    [docId],
  );

  /**
   * Edit button click handler (Request to edit the doc)
   */ const onClickEditHandler = React.useCallback(async () => {
    //  set the edit button on loading state
    setIsRequestingEdit(true);

    //  if could not get the current org user data, show an error toast
    if (!currentOrgUser?.id) {
      showToast(t("magicDocPrevPage.editRequestFailed", { msg: "Could not get the current user data" }), "error");
      setIsRequestingEdit(false);
      return;
    }

    //  invalidate the current doc query to make sure we get the latest data
    await queryClient.invalidateQueries(magicDocsQueryKeys.item(docId));
    try {
      const magicDocData: { data: any; json: OutputData } = await queryClient.fetchQuery(
        magicDocsQueryKeys.item(docId),
      );

      //  if failed to get the latest data
      if (!magicDocData) {
        // show error toast
        showToast(t("magicDocPrevPage.editRequestFailed", { msg: "Could not get the latest doc data" }), "error");
        //  reset the loading state
        setIsRequestingEdit(false);
        return;
      }

      //  get the checked in user id
      const checkedInUserId = magicDocData?.data?.checked_in_user_id;
      if (checkedInUserId) {
        // if the doc is checked in by another user
        if (checkedInUserId !== currentOrgUser?.id) {
          // lock the doc
          setIsLocked(true);
          // show the toast
          showToast(t("magicDocPrevPage.docLocked"), "info");
          // reset the loading state
          setIsRequestingEdit(false);
          return;
        }

        // if the current user is the one who checked in the doc
        // put the user into editing mode
        setIsEditing(true);
        // reset the loading state
        setIsRequestingEdit(false);
        return;
      }

      // if the doc is not checked in by any user
      // check in the doc
      await checkInCheckOutDoc.mutateAsync({
        modelId: docId,
        modelName: magicDocData?.data?.name,
        action: CheckInCheckOutMagicDocTypes.CHECK_IN,
      });
      // set the editing state
      setIsEditing(true);
      // reset the loading state
      setIsRequestingEdit(false);
    } catch (err: unknown) {
      // get the error message
      const errMsg = typeof err === "string" ? err : (err as AxiosError)?.message;
      // reset the loading state
      setIsRequestingEdit(false);
      // show error toast
      showToast(t("magicDocPrevPage.editRequestFailed", { msg: errMsg }), "error");
    }
  }, [checkInCheckOutDoc, currentOrgUser?.id, docId, t]);

  /**
   * Checking out the doc when the user navigates out of the page
   */
  const checkOutDocHandler = React.useCallback(async () => {
    // start the loading state
    setIsCheckingOut(true);

    try {
      await checkInCheckOutDoc.mutateAsync({
        modelName: data?.data?.name,
        modelId: docId,
        action: CheckInCheckOutMagicDocTypes.CHECK_OUT,
      });

      // reset the loading state
      setIsCheckingOut(false);
    } catch (err: unknown) {
      // reset the loading state
      setIsCheckingOut(false);
      // show error toast
      showToast(t("magicDocPrevPage.editRequestFailed", { msg: (err as AxiosError)?.message }), "error");
    }
  }, [checkInCheckOutDoc, data?.data?.name, docId, t]);

  /**
   * Cancel Editing handler (Navigate back to the magic docs page)
   */
  const cancelChangesHandler = React.useCallback(async () => {
    // if the user is in the editing mode but no changes made yet (leave wont get blocked), check out the doc
    if (isEditing && !isDirty.current) {
      checkOutDocHandler();
    }

    // navigate to the listing page
    navigate(`/${RoutePathType.MagicDocs}`);
  }, [checkOutDocHandler, isEditing, navigate]);

  /**
   * Create a new doc
   */
  const createNewDocHandler = React.useCallback(async () => {
    // set the loading state
    setIsSaving(true);

    try {
      // create a new doc
      const response = await createNewDoc.mutateAsync({
        name: docName,
        newDocJson: docDraftData,
      });

      // check if failed to push the doc content to S3
      if (response.errorToS3) {
        throw new Error("Error while pushing the doc content to S3");
      }

      // invalidate the current doc and the docs listing
      reloadModelsHandler();
      // reset the loading state
      setIsSaving(false);
      // reset the dirty state
      isDirty.current = false;
      // remove the draft data from the local storage
      localStorage.removeItem(docId);
      // navigate to the created doc
      navigate(`/${RoutePathType.MagicDocs}/${response.id}`);
      // show success toast
      showToast(t("magicDocPrevPage.docCreateSuccess"), "dark");

      // if there is an error
    } catch (err: unknown) {
      // reset the loading state
      setIsSaving(false);
      // show error toast
      showToast(t("magicDocPrevPage.docCreateFailed", { msg: (err as AxiosError)?.message }), "error");
    }
  }, [createNewDoc, docDraftData, docId, docName, navigate, reloadModelsHandler, t]);

  /**
   * Save Changes/Edits handler
   */
  const saveChangesHandler = React.useCallback(async () => {
    let docContent = data?.json;

    // if there is draft data, that is the content that should save
    if (docDraftData.blocks?.length) docContent = docDraftData;

    // if there is no content in the doc, do nothing
    if (!isDirty.current) return;

    try {
      setIsSaving(true);

      // if the user input a new name for the doc, rename the doc
      if (docName !== data?.data?.name) {
        await renameDoc.mutateAsync({
          modelId: docId,
          name: docName.trim(),
        });
      }

      const response = await saveDoc.mutateAsync({
        id: docId,
        name: data?.data.name,
        newDocJson: docContent,
        currVersionId: data?.data?.base_artifact?.id,
      });

      // check if there is an error while pushing the changes to S3
      if (response.errorToS3) {
        throw new Error("Error while pushing the changes to S3");
      }

      // invalidate the current doc and the docs listing
      reloadModelsHandler();
      // reset the saving state
      setIsSaving(false);
      // reset the dirty state
      isDirty.current = false;
      // remove the draft data from the local storage
      localStorage.removeItem(docId);
      // show success toast
      showToast(t(`magicDocPrevPage.docSaveSuccess`), "dark");
    } catch (err: unknown) {
      // reset the saving state
      setIsSaving(false);
      // Show error toast
      showToast(
        t(`magicDocPrevPage.docSaveFailed`, {
          msg: (err as AxiosError)?.message,
        }),
        "error",
      );
    }
  }, [
    data?.json,
    data?.data.name,
    data?.data?.base_artifact?.id,
    docDraftData,
    docName,
    saveDoc,
    docId,
    reloadModelsHandler,
    t,
    renameDoc,
  ]);

  /**
   * Copy Doc Link handler
   */
  const copyDocLinkHandler = React.useCallback(() => {
    navigator.clipboard
      .writeText(`${window.location.origin + location.pathname + location.search}`)
      .then(() => showToast(t("magicDocPrevPage.docLinkCopied"), "dark"))
      .catch(() => showToast(t("magicDocPrevPage.docLinkCopyFailed"), "error"));
  }, [location, t]);

  /**
   * Share Doc handler
   */
  const shareDocHandler = React.useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  /**
   * Download pdf handlers
   */
  const { toPDF, targetRef } = usePDF({ filename: "magicDoc.pdf", method: "open" });

  /**
   * Download Python Handler
   */
  const downloadPythonNotebookHandler = React.useCallback(() => {
    if (!data?.json) {
      throw new Error(`Error downloading file. json data not found`);
    }
    // if there is draft data, that is the content that should download
    const docContent = docDraftData.blocks?.length ? docDraftData : data.json;
    const notebook = JSON.stringify(convertEditorJsToJupyter(docContent), null, 2);
    const blob = new Blob([notebook], { type: "application/json" });
    const name = `${JSON.stringify(data?.data?.name).replace(/[\W_]+/g, "") ?? "magicDoc"}.ipynb`;
    saveAs(blob, name);
  }, [data, docDraftData]);

  /**
   * Leave/changes-discard confirmation handler
   */
  const leaveConfirmHandler = React.useCallback(() => {
    // leave loading state
    setIsLeaving(true);

    // reset the dirty state
    isDirty.current = false;

    // check out the doc
    if (isEditing && !isDirty.current) {
      checkOutDocHandler();
    }

    // remove the draft data from the local storage
    localStorage.removeItem(docId);
    // proceed to the next location
    if (blocker.state === "blocked") blocker.proceed();

    // reset the loading state
    setIsLeaving(false);
  }, [blocker, checkOutDocHandler, docId, isEditing]);

  /**
   * Leave/changes-discard cancel handler
   */
  const leaveCancelHandler = React.useCallback(() => {
    if (blocker.state === "blocked") blocker.reset();
  }, [blocker]);

  /**
   * Rename doc handler
   */
  const docNameChangeHandler = React.useCallback((newName: string) => {
    setDocName(newName);
    isDirty.current = true;
  }, []);

  /**
   * Page breadcrumbs
   */
  const breadcrumbs: ActionsBarProps["breadcrumbs"] = React.useMemo(
    () => [
      <IstariLink
        data-testid="md-breadcrumbs-link"
        key="1"
        href={`/${RoutePathType.MagicDocs}`}
        label={t("magicDocPrevPage.breadcrumbsLink")}
        sx={{ color: "inherit", borderColor: "inherit" }}
        color="gray"
        underline
        size={16}
      />,
      <Stack key="2" direction="row" alignItems="center" columnGap="8px">
        <SvgIcon
          component={MagicDocFile}
          inheritViewBox
          sx={{
            fontSize: "2rem",
          }}
        />
        <NameInput value={docName} onChange={docNameChangeHandler} readOnly={!isEditing && !props.isDraftPage} />
      </Stack>,
    ],
    [t, docName, docNameChangeHandler, isEditing, props.isDraftPage],
  );

  /**
   * Buttons props for the actions bar
   */
  const buttonsProps: Record<ActionButtonsNames, ActionButton> = React.useMemo(
    () => ({
      saveBtn: {
        type: "primary",
        label: t("common.save"),
        onClick: saveChangesHandler,
        disabled: !isDirty.current,
        loading: isSaving,
        testId: "md-save-btn",
      },
      createBtn: {
        type: "primary",
        label: t("common.create"),
        onClick: createNewDocHandler,
        disabled: !isDirty.current,
        loading: isSaving,
        testId: "md-create-btn",
      },
      editBtn: {
        type: "primary",
        label: t("common.edit"),
        onClick: onClickEditHandler,
        disabled: isLocked,
        loading: isRequestingEdit,
        testId: "md-edit-btn",
      },
      cancelBtn: {
        type: "gray",
        variant: "outlined",
        label: t("common.cancel"),
        onClick: cancelChangesHandler,
        loading: isCheckingOut,
        testId: "md-cancel-btn",
      },
      shareBtn: {
        icon: Users,
        onClick: shareDocHandler,
        type: "icon",
        sx: { fill: "none" },
        testId: "md-share-btn",
      },
      copyLinkBtn: {
        icon: Link,
        onClick: copyDocLinkHandler,
        type: "icon",
        sx: { fill: "none" },
        testId: "md-copyLink-btn",
      },
      downloadBtn: {
        icon: Download,
        onClick: toPDF,
        type: "icon",
        sx: { fill: "none" },
        testId: "md-download-btn",
      },
      codeBtn: {
        icon: Code,
        onClick: downloadPythonNotebookHandler,
        type: "icon",
        sx: { fill: "none" },
        testId: "md-code-btn",
      },
    }),
    [
      cancelChangesHandler,
      copyDocLinkHandler,
      createNewDocHandler,
      downloadPythonNotebookHandler,
      isCheckingOut,
      isLocked,
      isRequestingEdit,
      isSaving,
      onClickEditHandler,
      saveChangesHandler,
      shareDocHandler,
      t,
      toPDF,
    ],
  );

  /**
   * List of action buttons
   * ORDER MATTERS
   */
  const actionBtns = React.useMemo(() => {
    const btnsLists: ActionButton[][] = [[], []];

    // if the page is a draft page only show the cancel and create buttons
    if (props.isDraftPage) {
      btnsLists[1].push(buttonsProps.cancelBtn, buttonsProps.createBtn);
      return btnsLists;
    }

    // default buttons
    btnsLists[0].push(buttonsProps.copyLinkBtn, buttonsProps.downloadBtn, buttonsProps.codeBtn);

    // if the user can edit the doc (is an owner or an editor)
    if (canEditDoc) {
      // if the user is currently editing the doc show the cancel and save buttons
      if (isEditing) {
        btnsLists[1].push(buttonsProps.cancelBtn, buttonsProps.saveBtn);
      } else {
        // if the user is not editing (didn't click the edit button) show the edit button
        btnsLists[1].push(buttonsProps.editBtn);
      }
    }

    // if the user can share the doc (is an owner)
    if (canShareDoc) {
      btnsLists[0].push(buttonsProps.shareBtn);
    }

    return btnsLists;
  }, [props.isDraftPage, buttonsProps, canEditDoc, canShareDoc, isEditing]);

  /**
   * the UI (name/avatar) of the user who is currently editing the doc (locked the doc)
   */
  const lockedByUserUI = React.useMemo(() => {
    if (isLocked) {
      const checkedInUser = data?.data.checked_in_user;
      const checkedInUserName = checkedInUser ? `${checkedInUser.given_name} ${checkedInUser.family_name}` : "Username";

      return (
        <Stack direction="row" alignItems="center" columnGap="4px">
          <Typography variant="subtitle3" sx={{ color: customColors.gray[600] }}>{`${t(
            "magicDocPrevPage.beingEdited",
          )} ${checkedInUserName}`}</Typography>

          <Avatar
            sx={{
              width: "24px",
              height: "24px",
              fontSize: "1rem",
              lineHeight: "1rem",
              border: `1px solid ${customColors.gray[200]}`,
              backgroundColor: customColors.primary[200],
            }}
            src=""
          >
            <Typography variant="subtitle2">{checkedInUserName.charAt(0)?.toLocaleUpperCase()}</Typography>
          </Avatar>
        </Stack>
      );
    }

    return null;
  }, [data?.data.checked_in_user, isLocked, t]);

  /**
   * Update the isDirty state if there is a draft content in the local storage
   */
  React.useEffect(() => {
    const cachedDocContent = localStorage.getItem(docId);
    if (cachedDocContent) {
      isDirty.current = true;
    }
  }, [docId]);

  /**
   * Before unload event listener
   * Prevent the user from reloading/closing the browser tab if there are unsaved changes
   */
  React.useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty.current) {
        e.preventDefault(); // Needed to trigger the confirmation dialog
      }
    };

    // Add the event listener for the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component is unmounted
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  /**
   * let the user into the editing mode if the doc is already checked in by the user
   */
  React.useEffect(() => {
    const checkedInUserId = data?.data?.checked_in_user_id;

    if (checkedInUserId && checkedInUserId === currentOrgUser?.id) {
      setIsEditing(true);
    }
  }, [currentOrgUser?.id, data?.data?.checked_in_user_id]);

  /**
   * Update the doc name state
   */
  React.useEffect(() => {
    if (props.isDraftPage) setDocName("New Magic Doc");

    if (data?.data?.name) setDocName(data?.data?.name);
  }, [data?.data?.name, props.isDraftPage]);

  /**
   * For Draft Doc Page, set the initial draft data
   */
  React.useEffect(() => {
    if (props.isDraftPage) setDocDraftData(DRAFT_DOC_PAGE_DATA);
  }, [props.isDraftPage]);

  /**
   * If the JSON is still loading, show a spinner
   */
  if (isLoadingJson)
    return (
      <Stack
        data-testid="md-loading-spinner"
        sx={{
          pt: "120px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Stack>
    );

  /**
   * If there is an error fetching the doc JSON
   */
  if (isError) {
    const err = error as AxiosError;
    return (
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          pt: "120px",
          rowGap: "8px",
        }}
      >
        <Typography
          variant="subtitle3"
          color={customColors.red[300]}
          sx={{ whiteSpace: "pre-line", textAlign: "center" }}
        >
          {[HttpStatusCodeType.NotFound, HttpStatusCodeType.UnprocessableContent].includes(err.response?.status || 0)
            ? "Doc not found or invalid doc ID."
            : err.message}
        </Typography>

        <IstariLink
          size={14}
          href={`/${RoutePathType.MagicDocs}`}
          label="All Magic Docs"
          underline
          startIcon={EastIcon}
        />
      </Stack>
    );
  }

  return (
    <>
      {/* ---------- Share Doc Modal ---------- */}
      {isShareModalOpen && (
        <ManageAccessPopup
          testId="md-share-modal"
          open={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
          whatToShareId={docId}
          userGrants={data?.data?.user_grants}
          title={t("magicDocPrevPage.shareDocModalTitle")}
          reloadModels={reloadModelsHandler}
        />
      )}

      {/* ---------- Confirmation Modal ---------- */}
      {blocker.state === "blocked" && (
        <Modal
          actionButtonDataTestId="md-leave-confirm-button"
          actionButtonDisabled={isLeaving}
          actionButtonLabel={t("common.confirm")}
          actionButtonLoading={isLeaving}
          actionHandler={leaveConfirmHandler}
          content={
            <Typography variant="subtitle4" sx={{ color: customColors.gray[800] }}>
              {t("magicDocPrevPage.leaveConfirmModal.content")}
            </Typography>
          }
          open={blocker.state === "blocked"}
          title={t("magicDocPrevPage.leaveConfirmModal.title")}
          onCancel={leaveCancelHandler}
          onHide={leaveCancelHandler}
        />
      )}

      {/* ---------- Actions Bar ---------- */}
      <ActionsBar actionBtns={actionBtns} breadcrumbs={breadcrumbs} additionalDetails={lockedByUserUI} />

      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
        }}
      >
        {/* ---------- Side Bar ---------- */}
        <Stack
          sx={{
            flexGrow: 1,
            borderRight: "1px solid",
            borderColor: customColors.gray[100],
            maxWidth: "240px",
            // Media Print styles
            "@media print": {
              display: "none",
            },
          }}
        >
          <DocSideBar draftData={props.isDraftPage ? DRAFT_DOC_PAGE_DATA : docDraftData} />
        </Stack>

        {/* ---------- Editor space ---------- */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            maxHeight: `calc(100vh - ${TOP_HEADER_HEIGHT}px - ${ACTIONS_BAR_HEIGHT}px)`,
            marginRight: "2px",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: customColors.gray[400],
              borderRadius: "4px",
            },

            // Media Print styles
            "@media print": {
              overflow: "hidden",
              maxHeight: "none",
              marginRight: 0,
            },
          }}
        >
          <div ref={targetRef} id="doc-ref-container">
            <EditorJsWrapper
              data={props.isDraftPage ? DRAFT_DOC_PAGE_DATA : data?.json}
              onChange={onDocChangeHandler}
              /**
               * Readonly if the user is:
               * - not the owner or an editor
               * - not in the editing mode
               *
               * if the page is a draft page, the doc is always editable
               */
              readOnly={(!canEditDoc || !isEditing) && !props.isDraftPage}
            />
          </div>
        </Box>
      </Stack>
    </>
  );
};

export default DocEditor;
