import { OutputBlockData, OutputData } from "@editorjs/editorjs";
import ImageIcon from "@mui/icons-material/BrokenImageOutlined";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { Box, Stack } from "@mui/material";
import { MagicWand } from "phosphor-react";
import React from "react";
import { List } from "react-feather";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useGetMagicDoc } from "../../../../api/useMagicDocs";
import { Thread } from "../../../../assets/svg";
import IstariTabList from "../../../../components/IstariTabList";
import OutlineList from "./OutlineList";
import ThreadItem from "./ThreadItem";

const BLOCK_TYPES = {
  HEADER: "header",
  PARAGRAPH: "paragraph",
  IMAGE: "image",
  MAGIC_LINK: "magicLink",
  LIST: "list",
};

const TABS_VALUES = {
  OUTLINE: "outline",
  THREAD: "thread",
};

/**
 * TODO: ADD MORE TYPES LATER
 * @param type the Block type
 * @param level Headers block level
 * @returns the Icon or the Character that represents the block type in the sidebar
 */
const getBlockIcon = (type: string) => {
  switch (type) {
    case "text":
      return "T";
    case "image":
      return ImageIcon;
    case "json":
      return DataObjectIcon;
    default:
      return MagicWand;
  }
};

interface DocSideBarProps {
  draftData: OutputData;
}
const DocSideBar: React.FC<DocSideBarProps> = (props) => {
  // local state
  const [activeTab, setActiveTab] = React.useState(TABS_VALUES.OUTLINE);

  // hooks
  const { docId } = useParams();
  const { t } = useTranslation();
  const { data: docData } = useGetMagicDoc(docId || "", { enabled: !!docId });

  /**
   * Tabs list
   */
  const tabs = React.useMemo(
    () => [
      {
        label: t("magicDocPrevPage.sideBar.tabs.outline"),
        value: TABS_VALUES.OUTLINE,
        icon: { component: List },
      },
      {
        label: t("magicDocPrevPage.sideBar.tabs.thread"),
        value: TABS_VALUES.THREAD,
        icon: { component: Thread },
      },
    ],
    [t],
  );

  /**
   * Content of the Thread tab
   */
  const threadTabContent = React.useMemo(() => {
    const data = props.draftData?.blocks?.length ? props.draftData : docData?.json;

    return data?.blocks
      ?.filter((block: OutputBlockData) => block.type === BLOCK_TYPES.MAGIC_LINK)
      .map((item: OutputBlockData) => (
        <ThreadItem
          key={item.id}
          icon={getBlockIcon(item.data?.meta?.asset_type)}
          title={item.data?.meta?.artifact_name || "Istari Magic Link"}
          modelId={item.data?.meta?.model_id}
          artifactId={item.data?.meta?.artifact_id}
        />
      ));
  }, [docData?.json, props.draftData]);

  /**
   * Content of the Outline Tab
   */
  const outlineTabContent = React.useMemo(() => {
    const data = props.draftData?.blocks?.length ? props.draftData : docData?.json;
    const headers: OutputBlockData[] = data?.blocks?.filter(
      (block: OutputBlockData) => block.type === BLOCK_TYPES.HEADER,
    );

    return <OutlineList headers={headers} />;
  }, [docData?.json, props.draftData]);

  /**
   * Get the content of the active tab
   */
  const tabsContent = React.useMemo(() => {
    switch (activeTab) {
      case TABS_VALUES.THREAD:
        return <Box data-testid="md-threads">{threadTabContent}</Box>;

      case TABS_VALUES.OUTLINE:
      default:
        return outlineTabContent;
    }
  }, [activeTab, outlineTabContent, threadTabContent]);

  return (
    <Stack
      data-testid="md-sidebar"
      sx={{
        rowGap: "20px",
        padding: "16px 20px",
        flexGrow: 1,
      }}
    >
      {/* ----- Tabs ----- */}
      <IstariTabList
        activeTab={activeTab}
        tabChangeHandler={React.useCallback((_e, newValue) => setActiveTab(newValue), [])}
        tabs={tabs}
        variant="white"
        type="pill"
        color="gray"
      />

      {/* ----- Tabs contents ----- */}
      {tabsContent}
    </Stack>
  );
};

export default DocSideBar;
