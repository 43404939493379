import { Box, CircularProgress, Grid, SxProps, Theme } from "@mui/material";
import React, { Suspense, useContext, useEffect, useMemo, useState } from "react";
import { FileText, Home, Settings, Sliders } from "react-feather";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { DiamondIcon, IstariLogoColor, MagicDocFile } from "../../../assets/svg";
import { RoutePathType, SettingsSubpage } from "../../../enums";
import { UserMembershipContext } from "../../../lib/contexts/UserMembershipContext";
import { getEnv } from "../../../utils/get_env";
import TopBanner from "../../TopBanner";
import { SIDEBAR_WIDTH, TOP_HEADER_HEIGHT } from "../constants";
import Header from "../Header";
import SideBar from "../SideBar";
import { NavItem, SideBarProps } from "../types";

interface LayoutWithSideBarProps extends Pick<SideBarProps, "activeNavItem" | "navLists"> {
  children: React.ReactNode | React.ReactNode[];
  companyLogo?: React.ReactNode;
  currentPageTitle?: string;
  disableContentPadding?: boolean;
  sx?: SxProps<Theme>;
}

export const LayoutWithSideBar: React.FC<LayoutWithSideBarProps> = (props) => (
  <Box id="sss" component="main" sx={[{ minHeight: "100vh" }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}>
    {getEnv("VITE_ITAR") === "true" && <TopBanner />}
    <Header
      sx={{
        width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        ml: `${SIDEBAR_WIDTH}px`,
      }}
      currentPageTitle={props.currentPageTitle}
    />

    <Grid
      container
      columns={24}
      sx={{
        px: "0 !important",
        alignItems: "center",
        justifyContent: "center",
        pt: `${TOP_HEADER_HEIGHT}px`,
        minHeight: `calc(100vh - ${TOP_HEADER_HEIGHT}px)`,
      }}
    >
      <SideBar
        navLists={props.navLists}
        width={SIDEBAR_WIDTH}
        activeNavItem={props.activeNavItem}
        logo={props.companyLogo}
      />

      <Grid
        item
        sx={{
          alignSelf: "start",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          p: props.disableContentPadding ? 0 : "32px 50px",
          width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
        }}
      >
        {props.children}
      </Grid>
    </Grid>
  </Box>
);

function LeftSidebarLayout() {
  const [activeNavItem, setActiveNavItem] = useState("");

  const { t } = useTranslation();
  const location = useLocation();

  const { isCustomerAdmin } = useContext(UserMembershipContext);

  /**
   * Render the list of sidebar navigation links
   */
  const navigators = useMemo(() => {
    const arrayOfLists = [
      // ----- FIRST LIST
      {
        home: {
          label: t("sideBar.navItems.home"),
          value: RoutePathType.Upload,
          icon: Home,
        },
        allModels: {
          label: t("sideBar.navItems.allModels"),
          value: RoutePathType.AllModels,
          icon: DiamondIcon,
        },
      },

      // ----- SECOND LIST
      {
        documentation: {
          label: t("sideBar.navItems.documentation"),
          value: RoutePathType.Documentation,
          icon: FileText,
          children: [
            {
              label: "Welcome to Istari",
              value: `${getEnv("VITE_DOCUMENTATION_URL")}/docs/get-started/Welcome-to-Istari`,
            },
            {
              label: "Core Features",
              value: `${getEnv("VITE_DOCUMENTATION_URL")}/docs/get-started/Core-Features`,
            },
            {
              label: "Magic Doc Tutorial",
              value: `${getEnv("VITE_DOCUMENTATION_URL")}/docs/get-started/Magic-Doc-Tutorial`,
            },
          ],
        },
        settings: {
          label: t("sideBar.navItems.settings.label"),
          value: RoutePathType.Settings,
          icon: Settings,
          children: [
            {
              label: t("sideBar.navItems.settings.children.profileSettings"),
              value: SettingsSubpage.PROFILE_SETTINGS,
            },
            {
              label: t("sideBar.navItems.settings.children.changePassword"),
              value: SettingsSubpage.CHANGE_PASSWORD,
            },
            {
              label: t("sideBar.navItems.settings.children.developerSettings"),
              value: SettingsSubpage.DEVELOPER_SETTINGS,
            },
          ],
        },
        magicDocs: {
          label: t("sideBar.navItems.magicDocs"),
          value: RoutePathType.MagicDocs,
          icon: MagicDocFile,
        },
      },
    ];

    const defaultSecondNavList = arrayOfLists[1];
    const { documentation, settings, magicDocs } = defaultSecondNavList;

    const adminPanel = {
      label: t("sideBar.navItems.adminPanel"),
      value: RoutePathType.AdminPanel,
      icon: Sliders,
    };
    const lowerSetOfNavs = isCustomerAdmin
      ? {
          documentation,
          adminPanel,
          settings,
          magicDocs,
        }
      : {
          documentation,
          settings,
          magicDocs,
        };

    const arrayOfNavs = [arrayOfLists[0], lowerSetOfNavs];

    const navItems = Object.values(arrayOfNavs.reduce((obj, item) => Object.assign(obj, { ...item }), {})) as NavItem[];

    // get the navigators values
    const navItemsValues = Object.values(
      // Convert the navigators array of objects to a single object
      arrayOfNavs.reduce((obj, item) => Object.assign(obj, { ...item }), {}),
    )?.map((item) => (item as NavItem).value);

    // convert the array of lists objects to array of arrays of objects values
    const arrayOfNavLists = arrayOfNavs.map((list) => Object.values(list)) as NavItem[][];

    return {
      navItems,
      navItemsValues,
      arrayOfNavLists,
    };
  }, [t, isCustomerAdmin]);

  /**
   * Change the active nav item when the url pathname changes
   */
  useEffect(() => {
    // Get the current url pathname
    const currPathname = location.pathname.replace("/", "");

    // If the url pathname matches one of the navigators values? Change the active nav item
    if (navigators.navItemsValues?.includes(currPathname)) {
      setActiveNavItem(currPathname);
    }
  }, [location, navigators]);

  return (
    <LayoutWithSideBar
      navLists={navigators.arrayOfNavLists}
      activeNavItem={activeNavItem}
      companyLogo={<IstariLogoColor />}
      currentPageTitle={navigators.navItems?.find((item) => item.value === activeNavItem)?.label}
    >
      <Suspense fallback={<CircularProgress />}>
        <Outlet />
      </Suspense>
    </LayoutWithSideBar>
  );
}

export default LeftSidebarLayout;
