import { MoreVert } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useCallback, useState } from "react";

import { ModelActionType } from "../../../enums";
import IstariIconButton from "../../IstariIconButton";
import Submenu, { SubmenuItem, SubmenuProps } from "../../Submenu/Submenu";

interface Option extends SubmenuItem {
  name: ModelActionType;
}
export interface MoreOptionsProps {
  options: Option[];
  optionClickHandler: SubmenuProps["clickHandler"];
  variant?: "colored" | "white" | "outlined" | "lightBg";
}
const MoreOptions: React.FC<MoreOptionsProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const moreBtnHandler = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    // OPEN DROPDOWN MENU
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();

    // CLOSE DROPDOWN MENU
    setAnchorEl(null);
  }, []);

  return (
    <Box>
      <IstariIconButton
        data-testid="more-options-btn"
        color="gray"
        type="square"
        size={32}
        variant={props.variant || "white"}
        onClick={moreBtnHandler}
        Icon={MoreVert}
      />

      <Submenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        size="h-40"
        handleClose={handleClose}
        items={props.options}
        clickHandler={props.optionClickHandler}
      />
    </Box>
  );
};

export default MoreOptions;
