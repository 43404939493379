/**
 * @typedef {object} IstariMagicLinkToolData
 * @description Istari Magic Link Tool's input and output data format
 * @property {string} link — data url
 * @property {MetaData} meta — fetched link data
 */

/**
 * @typedef {object} MetaData
 * @description Fetched link meta data
 * @property {string} artifact_name - Name of the artifact to be fetched
 * @property {string} model_name - Name of the model to be fetched
 * @property {number} artifact_id - The Id of the artifact
 * @property {number} model_id - The Id of the model
 * @property {string} asset_url - The URL of the asset
 * @property {string} url - The URL pointing to the artifact
 * @property {string} last_updated - The last time the artifact was updated

 *
 */

/**
 * @typedef {object} IstariMagicLinkToolConfig
 * @property {string} endpoint - the endpoint for link data fetching
 * @property {object} headers - the headers used in the GET request
 */
import { IconChevronDown, IconChevronUp, IconLink, IconWarning } from "@codexteam/icons";
import axios from "axios";
import JSONFormatter from "json-formatter-js";
import * as OV from "online-3d-viewer";
import Papa from "papaparse";
import "url-polyfill";

import "./index.css";

/**
 * @typedef {object} UploadResponseFormat
 * @description This format expected from backend on link data fetching
 * @property {number} success  - 1 for successful uploading, 0 for failure
 * @property {MetaData} meta - Object with link data.
 *
 * Tool may have any data provided by backend, currently are supported by design:
 * title, description, image, url
 */
export default class IstariMagicLink {
  /**
   * Notify core that read-only mode supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconLink,
      title: "Magic Link",
    };
  }

  /**
   * Allow to press Enter inside the LinkTool input
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * @param {object} options - Tool constructor options fot from Editor.js
   * @param {IstariMagicLinkToolData} [options.data] - previously saved data
   * @param {IstariMagicLinkToolConfig} [options.config] - user config for Tool
   * @param {object} [options.api] - Editor.js API
   * @param {boolean} [options.readOnly] - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    /**
     * Tool's initial config
     */
    this.config = {
      endpoint: config.endpoint || "",
      accessToken: config.accessToken || "",
      headers: config.headers || {},
      s3AxiosInstance: config.s3AxiosInstance,
      sanitizer: config.sanitizer,
    };

    this.nodes = {
      wrapper: null,
      container: null,
      progress: null,
      input: null,
      inputHolder: null,
      linkContent: null,
      contentLoadingHolder: null,
    };

    this._data = {
      link: "",
      meta: {},
    };

    this.data = data;
  }

  extractIds(url) {
    const parts = url.split("/");
    const ids = {};

    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === "model" && i + 1 < parts.length) {
        ids.model_id = parts[i + 1];
      } else if (parts[i] === "artifact" && i + 1 < parts.length) {
        ids.artifact_id = parts[i + 1];
      }
    }

    return ids;
  }

  extractArtifactIdFromUrl(url) {
    try {
      const parsedUrl = new URL(url);
      // Validate the domain is Istari.app or a subdomain of Istari.app
      const { hostname } = parsedUrl;
      const isValidDomain = hostname === "istari.app" || hostname.endsWith(".istari.app");

      if (isValidDomain) {
        // Extract artifact_id parameter
        const params = new URLSearchParams(parsedUrl.search);
        const artifactId = params.get("artifact_id");
        if (artifactId) {
          return artifactId;
        }
      }
      // Return the original URL if the domain is not valid or artifact_id is not present
      return "";
    } catch (error) {
      console.error("Error parsing URL:", error);
      // Return the original URL in case of any errors during parsing
      return "";
    }
  }

  /**
   * Renders Block content
   *
   * @public
   *
   * @returns {HTMLDivElement}
   */
  render() {
    this.nodes.wrapper = this.make("div", this.CSS.baseClass);
    this.nodes.container = this.make("div", this.CSS.container);

    this.nodes.inputHolder = this.makeInputHolder();
    this.nodes.linkContent = this.prepareLinkPreview();
    this.nodes.contentLoadingHolder = this.makeContentLoadingHolder();

    // change the default behavior of clicking on the link content
    this.nodes.linkContent?.addEventListener("click", (event) => {
      event.stopPropagation();

      // Check if Ctrl key is pressed during click
      if (!(event.ctrlKey || event.metaKey)) {
        // Prevent default behavior if Ctrl key is not pressed
        event.preventDefault();
      }
    });

    /**
     * If Tool already has data, render link preview, otherwise insert input
     */
    if (Object.keys(this.data.meta).length) {
      this.nodes.container.appendChild(this.nodes.linkContent);

      // Create a container for the content loading holder
      // This will be used to display the loading spinner and the artifact URL
      const artifactUrlTextElement = document.createElement("div");
      artifactUrlTextElement.className = `artifact-url-text`;
      artifactUrlTextElement.textContent = this.data.meta.url;
      this.nodes.contentLoadingHolder.appendChild(artifactUrlTextElement);
      // Append the content loading holder to the main container
      this.nodes.container.appendChild(this.nodes.contentLoadingHolder);

      // Show the link preview
      this.fetchLinkData(this.data?.meta.url).then((res) => {
        // if fetching failed, status:0
        if (!res.statusCode) {
          // Render the failed fetching content holder
          this.nodes.container.appendChild(
            this.makeFailedFetchingContentHolder(
              `${this.data?.meta?.artifact_name || ""}
          Failed to fetch the link data. Error: ${res.result?.data?.detail}, Code: ${res.result?.status}`,
            ),
          );
          return;
        }

        this.showLinkPreview(this.data.meta);
      });
    } else {
      this.nodes.container.appendChild(this.nodes.inputHolder);
    }

    this.nodes.wrapper.appendChild(this.nodes.container);

    return this.nodes.wrapper;
  }

  /**
   * Return Block data
   *
   * @public
   *
   * @returns {IstariMagicLinkToolData}
   */
  save() {
    return this.data;
  }

  /**
   * Validate Block data
   * - check if given link is an empty string or not.
   *
   * @public
   *
   * @returns {boolean} false if saved data is incorrect, otherwise true
   */
  validate() {
    return this.data.link.trim() !== "";
  }

  /**
   * Stores all Tool's data
   *
   * @param {IstariMagicLinkToolData} data - data to store
   */
  set data(data) {
    this._data = { link: data.link || this._data.link, meta: data.meta || this._data.meta };
  }

  /**
   * Return Tool data
   *
   * @returns {LinkToolData}
   */
  get data() {
    return this._data;
  }

  /**
   * @returns {object} - Link Tool styles
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      input: this.api.styles.input,

      /**
       * Tool's classes
       */
      container: "link-tool",
      inputEl: "link-tool__input",
      inputHolder: "link-tool__input-holder",
      inputError: "link-tool__input-holder--error",
      linkContent: "link-tool__content",
      linkContentRendered: "link-tool__content--rendered",
      progress: "link-tool__progress",
      progressLoading: "link-tool__progress--loading",
      progressLoaded: "link-tool__progress--loaded",
      contentLoadingHolder: "link-tool__content-loading-holder",
      failedFetchingContentHolder: "link-tool__failed-fetching-content-holder",
    };
  }

  /**
   * Prepare content loading holder
   * @returns {HTMLElement}
   */
  makeContentLoadingHolder() {
    const contentLoadingHolder = this.make("div", this.CSS.contentLoadingHolder);

    const spinner = document.createElement("div");
    spinner.className = "content-spinner";

    contentLoadingHolder.appendChild(spinner);

    return contentLoadingHolder;
  }

  /**
   * Prepare holder for failed fetching content
   * @returns {HTMLElement}
   */
  makeFailedFetchingContentHolder(errorMsg) {
    const failedFetchingContentHolder = this.make("div", this.CSS.failedFetchingContentHolder);

    const warningIcon = document.createElement("span");
    warningIcon.innerHTML = IconWarning;
    warningIcon.className = "failed-content__warning-icon";

    failedFetchingContentHolder.appendChild(warningIcon);

    const errorMessage = document.createElement("p");
    errorMessage.className = "failed-content__error-message";
    errorMessage.textContent = errorMsg || "Failed to fetch content. Please try again.";

    failedFetchingContentHolder.appendChild(errorMessage);

    return failedFetchingContentHolder;
  }

  /**
   * Prepare input holder
   *
   * @returns {HTMLElement}
   */
  makeInputHolder() {
    const inputHolder = this.make("div", this.CSS.inputHolder);

    this.nodes.progress = this.make("label", this.CSS.progress);
    this.nodes.input = this.make("div", [this.CSS.input, this.CSS.inputEl], {
      contentEditable: !this.readOnly,
    });

    this.nodes.input.dataset.placeholder = this.api.i18n.t("Insert Istari Magic Link here...");
    this.nodes.input.setAttribute("data-testid", "magic-link-input");

    if (!this.readOnly) {
      this.nodes.input.addEventListener("paste", (event) => {
        this.startFetching(event);
      });

      this.nodes.input.addEventListener("keydown", (event) => {
        const [ENTER, A] = [13, 65];
        const cmdPressed = event.ctrlKey || event.metaKey;

        switch (event.keyCode) {
          case ENTER:
            event.preventDefault();
            event.stopPropagation();

            this.startFetching(event);
            break;
          case A:
            if (cmdPressed) {
              this.selectLinkUrl(event);
            }
            break;
          default:
            break;
        }
      });
    }

    inputHolder.appendChild(this.nodes.progress);
    inputHolder.appendChild(this.nodes.input);

    return inputHolder;
  }

  /**
   * Activates link data fetching by url
   *
   * @param {PasteEvent|KeyboardEvent} event - fetching could be fired by a pase or keydown events
   */
  startFetching(event) {
    let url = this.nodes.input.textContent;

    if (event.type === "paste") {
      url = (event.clipboardData || window.clipboardData).getData("text");
    }

    /**
     *
     * @param {string} str
     * @returns {boolean}
     */
    function isValidURL(str) {
      try {
        const parsedUrl = new URL(str);
        // Check if the URL has a valid protocol
        const validProtocols = ["http:", "https:"];
        if (!validProtocols.includes(parsedUrl.protocol)) {
          return false;
        }

        return true;
      } catch (error) {
        return false;
      }
    }

    // check if no url provided
    if (!url || !url.trim()) {
      return;
    }

    // clear the error style if it was applied
    this.removeErrorStyle();

    // check if the pasted url is not a valid url
    if (!isValidURL(url)) {
      this.applyErrorStyle();

      this.api.notifier.show({
        message: "URL is not valid!",
        style: "error",
        time: 1500,
      });
      return;
    }

    this.fetchLinkData(url).then((res) => {
      // If failed to fetch link data
      if (!res.statusCode) {
        this.applyErrorStyle();
        let msg = "";
        switch (res.result?.status) {
          case 404:
            msg = "Derived artifact not found!";
            break;
          case 422:
            msg = "URL is not a valid artifact URL!";
            break;
          default:
            msg = "";
        }

        if (msg) {
          this.api.notifier.show({
            message: msg,
            style: "error",
          });
        }

        return;
      }
      // if successfully fetched link data, render the link content
      this.showLinkPreview(this.data.meta);
    });
  }

  /**
   * If previous link data fetching failed, remove error styles
   */
  removeErrorStyle() {
    this.nodes.inputHolder.classList.remove(this.CSS.inputError);
    this.nodes.inputHolder.insertBefore(this.nodes.progress, this.nodes.input);
  }

  /**
   * Select LinkTool input content by CMD+A
   *
   * @param {KeyboardEvent} event - keydown
   */
  selectLinkUrl(event) {
    event.preventDefault();
    event.stopPropagation();

    const selection = window.getSelection();
    const range = new Range();

    const currentNode = selection.anchorNode.parentNode;
    const currentItem = currentNode.closest(`.${this.CSS.inputHolder}`);
    const inputElement = currentItem.querySelector(`.${this.CSS.inputEl}`);

    range.selectNodeContents(inputElement);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Prepare link preview holder
   *
   * @returns {HTMLElement}
   */
  prepareLinkPreview() {
    const holder = this.make("a", this.CSS.linkContent, {
      target: "_blank",
      rel: "nofollow noindex noreferrer",
    });
    holder.setAttribute("data-testid", "magic-link-rendered");

    return holder;
  }

  /**
   * Renders an image element in the link content element
   * @param {string} image - the image URL to be used for the image
   * @returns {HTMLElement}
   */
  async renderImage(imageUrl) {
    const imageContainer = document.createElement("div");
    imageContainer.className = "magicLink-image-container";
    const imgElement = document.createElement("img");

    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        throw new Error(`Image fetch failed with status: ${response.status}`);
      }

      // create blob --> blob url
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      imgElement.src = blobUrl;
      imgElement.alt = "Link preview image";
      imgElement.style.maxWidth = "100%"; // Ensures the image fits within the container
      imageContainer.appendChild(imgElement);
      this.nodes.linkContent.appendChild(imageContainer);
    } catch (error) {
      // show toast
      this.api.notifier.show({
        message: "Failed to load image",
        style: "error",
        time: 1500,
      });
    }

    return imageContainer;
  }

  /**
   * Renders a text element in the link content element
   * @param {string} text - the text to be displayed
   * @returns {HTMLElement}
   */
  renderText(text) {
    const textElement = document.createElement("div");
    const formattedText = text.replace(/\n/g, "<br>");
    textElement.innerHTML = formattedText;
    textElement.style.wordWrap = "break-word"; // Ensures the text wraps and does not overflow
    this.nodes.linkContent.appendChild(textElement);
    return textElement;
  }

  /**
   * Renders a 3D object inside of a 3D viewer in the link content element
   * @param {File} file - the file that represents the 3D object to be displayed
   * @returns {HTMLElement}
   */
  render3D(file) {
    // Create the viewer div
    const viewerDiv = document.createElement("div");

    viewerDiv.style.width = "100%";
    viewerDiv.style.minHeight = "350px";
    this.nodes.linkContent.appendChild(viewerDiv);
    if (document.isLoadingEditorJS === true) {
      viewerDiv.className = "online_3d_viewer";
    } else {
      // get the parent element of the viewer
      const parentDiv = viewerDiv;

      // initialize the viewer with the parent element and some parameters
      const viewer = new OV.EmbeddedViewer(parentDiv, {
        backgroundColor: new OV.RGBAColor(255, 255, 255, 0),
        defaultColor: new OV.RGBColor(200, 200, 200),
        edgeSettings: new OV.EdgeSettings(false, new OV.RGBColor(0, 0, 0), 1),
      });
      // load a model providing model urls
      viewer.LoadModelFromFileList([file]);
    }

    return viewerDiv;
  }

  /**
   * Renders a JSON element in the link content element using json-formatter-js package
   * @param {object} json - the json object to be displayed
   * @returns {HTMLElement}
   */
  renderJSON(json) {
    // Initialize the JSON formatter
    const formatter = new JSONFormatter(json, 1, {
      hoverPreviewEnabled: true,
      hoverPreviewArrayCount: 100,
      hoverPreviewFieldCount: 5,
      animateOpen: true,
      animateClose: true,
    });

    // Create a container for the JSON
    const jsonContainer = document.createElement("div");
    jsonContainer.className = "magicLink-json-container";

    // Append the JSON formatter render to the container
    jsonContainer.appendChild(formatter.render());

    // Append the container to the link content element
    this.nodes.linkContent.appendChild(jsonContainer);

    return jsonContainer;
  }

  /**
   * Renders an HTML element in the link content element
   * @param {string} html - the HTML to be displayed
   * @returns {HTMLElement}
   */
  renderHTML(html) {
    const htmlContainer = document.createElement("div");
    htmlContainer.innerHTML = html;
    this.nodes.linkContent.appendChild(htmlContainer);
    return htmlContainer;
  }

  /**
   * Renders an table element in the link content element
   * @param {string} sheet - the of the sheet to be rendered
   * @returns {HTMLElement}
   */
  renderSheet(text) {
    // Create the container for the sheet table
    const sheetContainer = document.createElement("div");

    // Create the initial table structure
    sheetContainer.innerHTML = `
    <table border=1 style="width: 90%; margin: auto;" class="sheet-table">
    <thead>
        <tr id="sheet-tableHeader">
        </tr>
    </thead>
    <tbody id="sheet-tableBody">
    </tbody>
    </table>
    `;

    /**
     * For creating the table's elements
     * @param {string} htmlTag
     * @param {string} innerText
     * @param {string} idParent
     */
    function createDataElement(htmlTag, innerText, idParent) {
      const node = document.createElement(htmlTag);
      node.style.padding = "0px 4px";
      const textnode = document.createTextNode(innerText);
      node.appendChild(textnode);
      const element = sheetContainer.querySelector(`#${idParent}`);
      if (element) {
        element.appendChild(node);
      }
    }

    /**
     * For creating the table's header elements
     * @param {string} columnText
     */
    function createHeaderElement(columnText) {
      createDataElement("th", columnText, "sheet-tableHeader");
    }

    /**
     * For creating the table's cell elements
     * @param {number} rowIndex
     * @param {number} dataIndex
     * @param {string} cellText
     */
    function createCellData(rowIndex, dataIndex, cellText) {
      const elementId = `sheet-row${rowIndex}`;

      if (dataIndex === 0) {
        const node = document.createElement("tr");
        node.setAttribute("id", elementId);
        node.className = "sheet-row";
        const element = sheetContainer.querySelector(`#sheet-tableBody`);
        if (element) {
          element.appendChild(node);
        }

        createDataElement("td", cellText, elementId);
      } else {
        createDataElement("td", cellText, elementId);
      }
    }

    // Parse the CSV text --> table's data --> create the table elements
    Papa.parse(text, {
      complete(results) {
        for (let i = 0; i < results.data.length; i++) {
          if (i === 0) {
            for (let j = 0; j < results.data[i].length; j++) {
              createHeaderElement(results.data[i][j]);
            }
          }
          if (i > 0) {
            for (let j = 0; j < results.data[i].length; j++) {
              createCellData(i, j, results.data[i][j]);
            }
          }
        }
      },
    });

    this.nodes.linkContent.appendChild(sheetContainer);
    return sheetContainer;
  }

  /**
   * Renders an error in the link content element
   * @param {string} error - the error to be displayed
   * @returns {HTMLElement}
   */
  renderError(error) {
    const errorElement = document.createElement("div");
    errorElement.className = "error-message";
    errorElement.textContent = error;
    errorElement.style.color = "red"; // Use a color to indicate an error visually
    errorElement.style.padding = "10px";
    errorElement.style.marginTop = "5px";
    errorElement.style.border = "1px solid red";
    errorElement.style.backgroundColor = "rgba(255, 0, 0, 0.1)";
    errorElement.style.borderRadius = "4px";
    this.nodes.linkContent.appendChild(errorElement);
    return errorElement;
  }

  /**
   * Renders a message in the link content element
   * @param {string} message - the message to be displayed
   * @returns {HTMLElement}
   */
  renderMessage(message) {
    const messageElement = document.createElement("div");
    messageElement.textContent = message;
    messageElement.style.color = "#333"; // Neutral color for general messages
    messageElement.style.padding = "10px";
    messageElement.style.marginTop = "5px";
    messageElement.style.border = "1px solid #ddd";
    messageElement.style.backgroundColor = "#f4f4f4";
    messageElement.style.borderRadius = "4px";
    this.nodes.linkContent.appendChild(messageElement);
    return messageElement;
  }

  /**
   * Renders a hyperlink in the link content element
   * @param {string} url - the URL for the hyperlink
   * @param {string} linkText - the text to be displayed for the hyperlink
   * @returns {HTMLElement}
   */
  async renderDownloadButton({ url, name, extension }, linkText) {
    const blob = await this.config.s3AxiosInstance
      ?.get(url, {
        withCredentials: false,
        responseType: "blob",
      })
      .then((r) => new Blob([r]));

    const blobLink = URL.createObjectURL(blob);

    // Download function
    function downloadFile(href, downloadName) {
      // Create an anchor element
      const link = document.createElement("a");
      link.href = href; // URL of the file to download

      // Specify the filename to save as
      link.download = downloadName;

      // Trigger a click event on the anchor element
      link.click();

      // Remove the anchor element from the DOM
      document.body.removeChild(link);
    }

    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.justifyContent = "center";
    container.style.alignItems = "center";
    container.style.columnGap = "3px";

    const downloadBtn = document.createElement("button");
    // remove styles and make it like text
    downloadBtn.style.border = "none";
    downloadBtn.style.background = "none";
    downloadBtn.style.font = "inherit";
    downloadBtn.style.padding = "0";
    downloadBtn.style.cursor = "pointer";
    downloadBtn.style.color = "#007bff";
    downloadBtn.textContent = linkText || "Click to download";

    // Add click event to download the file
    downloadBtn.addEventListener("click", (event) => {
      // if the user is holding the Ctrl or CMD key, don't download the file
      if (event.ctrlKey || event.metaKey) return;
      downloadFile(blobLink, `${name}.${extension}`);
    });

    const helperText = document.createElement("span");
    helperText.textContent = "This artifact is not previewable. ";

    container.appendChild(helperText);
    container.appendChild(downloadBtn);

    this.nodes.linkContent.appendChild(container);
    return container;
  }

  /**
   * Compose link preview from fetched data
   *
   * @param {MetaData} meta - link meta data
   */
  async showLinkPreview(metaData) {
    const { asset_url, artifact_name, artifact_extension } = metaData;
    // Create the toggle button
    this.nodes.toggleButton = document.createElement("button");
    this.nodes.toggleButton.innerHTML = IconChevronDown; // Assuming arrowDown is your expand icon

    this.nodes.toggleButton.classList.add("collapse-button");
    this.nodes.toggleButton.setAttribute("data-testid", "ml-collapse-button");

    this.nodes.toggleButton.addEventListener("click", () => {
      const isCollapsed = this.nodes.linkContent.style.display === "none";
      this.nodes.linkContent.style.display = isCollapsed ? "block" : "none";

      // Toggle between arrowDown and arrowUp based on the collapsed state
      this.nodes.toggleButton.setAttribute("title", isCollapsed ? "Collapse" : "Expand");
      this.nodes.toggleButton.innerHTML = isCollapsed ? IconChevronUp : IconChevronDown; // Swap icons
    });

    // Append your toggle button to the wrapper or wherever appropriate
    this.nodes.container.appendChild(this.nodes.toggleButton);

    this.nodes.container.appendChild(this.nodes.linkContent);

    switch (artifact_extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "svg":
      case "bmp":
        this.renderImage(asset_url);

        break;
      case "txt":
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                if (this.config.sanitizer) return this.config.sanitizer(data);
                return data;
              },
            ],
          })
          .then((response) => this.renderText(response));

        break;
      case "json":
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                const parsedJson = JSON.parse(data);
                if (this.config.sanitizer) return this.config.sanitizer(parsedJson);
                return data;
              },
            ],
          })
          .then((response) => this.renderJSON(response));

        break;
      case "html":
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                if (this.config.sanitizer) return this.config.sanitizer(data);
                return data;
              },
            ],
          })
          .then((response) => this.renderHTML(response));

        break;
      case "xlsx":
      case "xls":
      case "csv":
      case "workbook":
      case "sheet":
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                if (this.config.sanitizer) return this.config.sanitizer(data);
                return data;
              },
            ],
          })
          .then((response) => this.renderSheet(response));

        break;
      case "jupyter":
        // TODO: Create Jupyter render div
        break;
      case "app":
        // TODO: Create Streamlit app render div
        break;
      case "obj":
        // TODO: Add more 3D file formats as needed
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                if (this.config.sanitizer) return this.config.sanitizer(data);
                return data;
              },
            ],
          })
          .then((response) => {
            const file = new File([response], `${artifact_name}.${artifact_extension}`);
            this.render3D(file);
          });

        break;
      case "error":
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                if (this.config.sanitizer) return this.config.sanitizer(data);
                return data;
              },
            ],
          })
          .then((response) => this.renderError(response));

        break;
      case "message":
        this.config.s3AxiosInstance
          ?.get(asset_url, {
            withCredentials: false,
            transformResponse: [
              (data) => {
                if (this.config.sanitizer) return this.config.sanitizer(data);
                return data;
              },
            ],
          })
          .then((response) => this.renderMessage(response));

        break;
      default:
        this.renderDownloadButton({
          url: asset_url,
          name: artifact_name,
          extension: artifact_extension,
        });
    }

    // mark link content as rendered
    this.nodes.linkContent.classList.add(this.CSS.linkContentRendered);

    // Create a flex container for the artifact information and the tags
    const infoAndTagsContainer = document.createElement("div");
    infoAndTagsContainer.className = "info-and-tags-container";
    infoAndTagsContainer.style.display = "flex";
    infoAndTagsContainer.style.justifyContent = "space-between";
    infoAndTagsContainer.style.alignItems = "center";
    infoAndTagsContainer.style.backgroundColor = "#f0f0f0"; // Light grey background
    infoAndTagsContainer.style.color = "#333"; // Dark text color for contrast
    infoAndTagsContainer.style.fontSize = "12px"; // Small font size
    infoAndTagsContainer.style.padding = "10px"; // Padding for spacing
    infoAndTagsContainer.style.marginTop = "10px"; // Margin top to separate from content above
    infoAndTagsContainer.style.borderTop = "1px solid #ccc"; // Top border for separation

    // Create the artifact information text
    const artifactInfoText = document.createElement("span");
    artifactInfoText.className = "artifact-info-text";
    artifactInfoText.textContent = `Artifact Name: ${metaData.artifact_name}, ID: ${metaData.artifact_id}, URL: ${metaData.url},Last Updated: ${metaData.last_updated}`;

    // Create a container for the tags to keep them together
    const tagsContainer = document.createElement("div");
    tagsContainer.className = "tags";
    tagsContainer.style.display = "flex";
    tagsContainer.style.gap = "10px"; // Adds space between the tags

    // Create and style the Security Level tag
    const securityLevelTag = document.createElement("div");
    securityLevelTag.className = "tag tag__security-level";
    securityLevelTag.textContent = "Level 1";
    this.styleTag(securityLevelTag, "green");

    // Create and style the Information Security tag
    const infoSecurityTag = document.createElement("div");
    infoSecurityTag.className = "tag tag__info-security";
    infoSecurityTag.textContent = "GDPR";
    this.styleTag(infoSecurityTag, "green");

    // remove content loading holder
    this.nodes.contentLoadingHolder.remove();

    // Append tags to their container
    tagsContainer.appendChild(securityLevelTag);
    tagsContainer.appendChild(infoSecurityTag);

    // Append the artifact information text and the tags container to the flex container
    infoAndTagsContainer.appendChild(artifactInfoText);
    infoAndTagsContainer.appendChild(tagsContainer);

    // Append the flex container to the main container
    this.nodes.container.appendChild(infoAndTagsContainer);
  }

  // Helper function to style the tags
  styleTag(tagElement, backgroundColor) {
    tagElement.style.backgroundColor = backgroundColor;
    tagElement.style.color = "#fff"; // White text color
    tagElement.style.padding = "5px 10px"; // Padding around the text
    tagElement.style.borderRadius = "15px"; // Rounded corners
    tagElement.style.fontSize = "12px"; // Small font size for the tag
    tagElement.style.fontWeight = "bold"; // Optional: makes the text bold
    tagElement.style.display = "inline-block"; // Treat the div like an inline element
    tagElement.style.boxShadow = "0 2px 2px rgba(0,0,0,0.2)"; // Optional: adds a subtle shadow
  }

  /**
   * Show loading progress bar
   */
  showProgress() {
    this.nodes.progress.classList.add(this.CSS.progressLoading);
  }

  /**
   * Hide loading progress bar
   *
   * @returns {Promise<void>}
   */
  hideProgress() {
    return new Promise((resolve) => {
      this.nodes.progress.classList.remove(this.CSS.progressLoading);
      this.nodes.progress.classList.add(this.CSS.progressLoaded);

      setTimeout(resolve, 500);
    });
  }

  /**
   * If data fetching failed, set input error style
   */
  applyErrorStyle() {
    this.nodes.inputHolder.classList.add(this.CSS.inputError);
    this.nodes.progress.remove();
  }

  /**
   * Sends to backend pasted url and receives link data
   *
   * @param {string} url - link source url
   */
  async fetchLinkData(url) {
    this.showProgress();
    const ids = this.extractIds(url);
    const { artifact_id } = ids;
    const { model_id } = ids;

    try {
      // Update headers to include the Authorization header with the JWT token
      const headers = {
        ...this.config.headers,
        Authorization: `Bearer ${this.config.accessToken}`,
      };

      const res = await axios.get(`${this.config.endpoint}/model/${model_id}/artifact:MagicDoc/${artifact_id}`, {
        headers,
      });

      this.onFetch(res?.data, url);

      // Set the href to the artifact URL
      this.nodes.linkContent?.setAttribute("href", `/file/${model_id}/artifact/${artifact_id}`);

      return {
        statusCode: 1,
        result: res,
      };
    } catch (error) {
      // Remove the loading holder
      this.nodes.contentLoadingHolder.remove();

      return {
        statusCode: 0,
        result: error,
      };
    }
  }

  /**
   * Link data fetching callback
   *
   * @param {UploadResponseFormat} response - backend response
   */
  onFetch(response, url) {
    if (!response) {
      return;
    }

    // Extracting the relevant data from the response
    const { name, id, model_id, updated_at, signed_download_url, artifact_extension } = response;

    const metaData = {
      artifact_name: name,
      artifact_id: id,
      model_name: "Model Name Placeholder", // You might need to fetch the model name separately if required
      model_id,
      artifact_extension,
      last_updated: updated_at,
      asset_url: signed_download_url,
      url,
    };

    this.data = {
      meta: metaData,
      link: url,
    };

    this.hideProgress().then(() => {
      this.nodes.inputHolder.remove();
    });
  }

  /**
   * Helper method for elements creation
   *
   * @param {string} tagName - name of creating element
   * @param {string|string[]} [classNames] - list of CSS classes to add
   * @param {object} [attributes] - object with attributes to add
   * @returns {HTMLElement}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }
}
