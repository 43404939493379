import {
  Avatar,
  Stack,
  styled,
  SvgIcon,
  SxProps,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  Theme,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";

import { customColors } from "../../lib/utils/colors";

export const TABS_TYPES = Object.freeze({
  UNDERLINE: "underline",
  PILL: "pill",
});
type TabsTypes = (typeof TABS_TYPES)[keyof typeof TABS_TYPES];
type Colors = "blue" | "gray";
export const COLORS = Object.freeze({
  BLUE: "blue",
  GRAY: "gray",
});

const FONT_COLOR = Object.freeze({
  blue: { normal: customColors.primary[400], hover: customColors.primary[300] },
  gray: { normal: customColors.gray[800], hover: customColors.gray[700] },
});

export const TAB_SIZES = Object.freeze({
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
});
type TabSizes = "small" | "medium" | "large";

export const TABS_VARIANTS = Object.freeze({
  NO_BG: "no-background",
  WHITE: "white",
});
type TabsVariants = "no-background" | "white";

const LABEL_VARIANTS = Object.freeze({
  small: "subtitle3b",
  medium: "subtitle2b",
  large: "subtitle1b",
});

const ICON_SIZE = Object.freeze({
  small: "1.25rem",
  medium: "1.25rem",
  large: "1.5rem",
});

const PADDING = Object.freeze({
  pill: { small: "6px 8px", medium: "8px 12px", large: "10px 16px" },
  underline: { small: "10px 0px", medium: "12px 0px", large: "16px 0px" },
});

const TABS_GAP = Object.freeze({
  pill: { small: "20px", medium: "20px", large: "20px" },
  underline: { small: "20px", medium: "24px", large: "32px" },
});

const COUNT_VARIANTS = Object.freeze({
  small: "subtitle4",
  medium: "subtitle4",
  large: "subtitle3",
});

const TABS_BGCOLOR = Object.freeze({
  "no-background": "transparent",
  white: customColors.white[0],
});

const PILL_TAB_INDICATOR_COLOR = Object.freeze({
  "no-background": customColors.gray[200],
  white: customColors.gray[100],
});

interface StyledTabProps extends TabProps {
  type: TabsTypes;
  size: TabSizes;
  color: Colors;
}
const StyledTab = styled(Tab, {
  // TO PREVENT FORWARDING THESE PROPS TO THE MUI BUTTON COMPONENT
  shouldForwardProp: (prop) => !["icon", "type", "iconPosition", "size"].includes(prop as never),
})((props: StyledTabProps) => ({
  padding: PADDING[props.type][props.size],
  color: FONT_COLOR[props.color].normal,
  borderRadius: "4px",
  zIndex: 1,
  transition: "color 0.25s",
  textTransform: "none",

  minWidth: "unset",
  maxWidth: "unset",
  maxHeight: "unset",
  minHeight: "unset",

  "&.Mui-selected": {
    color: FONT_COLOR[props.color].normal,

    "&.Mui-disabled": {
      opacity: 0.7,
    },
  },

  "&:hover": {
    color: FONT_COLOR[props.color].hover,
  },
}));

interface TabProperties {
  value: string;
  label: string;
  icon?: {
    component: React.ElementType;
    styles?: SxProps<Theme>;
  };
  count?: number | string;
  disabled?: boolean;
}
interface IstariTabProps extends TabProperties {
  size: TabSizes;
  color: Colors;
  type: TabsTypes;
}

/**
 * Tab component
 */
const IstariTab: React.FC<IstariTabProps> = ({ icon, label, size, count, value, color, type, ...others }) => {
  const renderLabel = useMemo(
    () => (
      <Stack direction="row" columnGap="8px" sx={{ color: "inherit" }}>
        {icon && (
          <SvgIcon
            component={icon.component}
            inheritViewBox
            sx={{ fontSize: ICON_SIZE[size], fill: "inherit", ...icon.styles }}
          />
        )}

        <Typography variant={LABEL_VARIANTS[size]} sx={{ color: "inherit" }}>
          {label}
        </Typography>

        {(typeof count === "number" || count) && (
          <Avatar
            sx={{
              width: ICON_SIZE[size],
              height: ICON_SIZE[size],
              bgcolor: customColors.primary[400],
            }}
          >
            <Typography
              variant={COUNT_VARIANTS[size]}
              sx={{
                color: customColors.white[0],
              }}
            >
              {count}
            </Typography>
          </Avatar>
        )}
      </Stack>
    ),
    [count, icon, label, size],
  );

  return (
    <StyledTab {...others} disableRipple label={renderLabel} value={value} size={size} color={color} type={type} />
  );
};

type ExcludeProps = "value" | "variant" | "type";
interface IstariTabListProps extends Omit<TabsProps, ExcludeProps> {
  tabs: Array<TabProperties>;
  activeTab: string;
  tabChangeHandler: (_event: React.SyntheticEvent, _value: string) => void;
  /**
   * size of each individual tab
   * @default "small"
   */
  size?: TabSizes;
  /**
   * color of each individual tab
   * @default "blue"
   */
  color?: Colors;
  /**
   * type of the tab list
   * @default "underline"
   */
  type?: TabsTypes;
  /**
   * variant of the tabs list
   * @default "white"
   */
  variant?: TabsVariants;
  disabled?: boolean;
}

/**
 *  IstariTabList Component
 */
const IstariTabList: React.FC<IstariTabListProps> = ({
  tabs,
  activeTab,
  tabChangeHandler,
  size = "small",
  color = "blue",
  type = "underline",
  variant = "white",
  disabled,
  ...others
}) => {
  const renderTabs = useMemo(
    () =>
      tabs?.map((tab) => (
        <IstariTab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          count={tab.count}
          icon={tab.icon}
          size={size}
          type={type}
          color={color}
          disabled={disabled || tab.disabled}
          data-testid={`istari-tab-${tab.value}`}
        />
      )),
    [color, disabled, size, tabs, type],
  );

  return (
    <Tabs
      {...others}
      onChange={tabChangeHandler}
      value={activeTab}
      TabIndicatorProps={{
        sx: {
          height: type === TABS_TYPES.UNDERLINE ? "4px" : "100%",
          bgcolor: type === TABS_TYPES.UNDERLINE ? customColors.primary[400] : PILL_TAB_INDICATOR_COLOR[variant],

          borderRadius: type === TABS_TYPES.UNDERLINE ? "none" : "4px",
          opacity: disabled ? 0.7 : 1,
        },
      }}
      sx={{
        minHeight: "unset",
        bgcolor: TABS_BGCOLOR[variant],

        "& .MuiTabs-flexContainer": {
          columnGap: TABS_GAP[type][size],
        },
      }}
    >
      {renderTabs}
    </Tabs>
  );
};

export default IstariTabList;
