import { AppBar, Divider, Grid, SxProps, Theme, Toolbar, Typography } from "@mui/material";
import React from "react";

import { RoutePathType } from "../../../enums";
import { customColors } from "../../../lib/utils/colors";
import { getEnv } from "../../../utils/get_env";
import LinkLogo from "../../LinkLogo";
import FilesStatusButton from "./partials/FilesStatusButton";
import HelpAndSuggestionsButton from "./partials/SuggestionButton";
import UserMenu from "./partials/UserMenu";

interface HeaderProps {
  withLogo?: boolean;
  sx?: SxProps<Theme>;
  currentPageTitle?: string;
}
const Header: React.FC<HeaderProps> = (props) => (
  <AppBar
    data-testid="page-header"
    elevation={0}
    color="inherit"
    sx={[
      {
        borderBottom: `1px solid ${customColors.gray[100]}`,
        position: "fixed",
        height: "60px",
        top: getEnv("VITE_ITAR") === "true" ? "29px" : 0,
        // Print media query
        "@media print": {
          display: "none",
        },
      },
      ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
    ]}
  >
    <Toolbar disableGutters sx={{ px: "24px", py: "10px", minHeight: { xs: "auto" } }}>
      <Grid container sx={{ height: "100%" }} alignItems="center" justifyContent="space-between">
        {/* ---------- Logo OR Page's title ---------- */}
        {(props.withLogo || props.currentPageTitle) && (
          <Grid item xs={4}>
            {props.withLogo && <LinkLogo to={RoutePathType.Root} sx={{ display: "inline-flex" }} />}

            {props.currentPageTitle && (
              <Typography
                aria-level={1}
                role="heading"
                data-testid="header-page-title"
                variant="subtitle2b"
                sx={{ color: customColors.gray[800] }}
              >
                {props.currentPageTitle}
              </Typography>
            )}
          </Grid>
        )}

        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            columnGap: "16px",
          }}
        >
          <HelpAndSuggestionsButton />
          <FilesStatusButton />

          <Divider
            variant="middle"
            orientation="vertical"
            flexItem
            sx={{ borderColor: customColors.gray[100], margin: 0 }}
          />

          <UserMenu />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default Header;
