import { useMutation, useQuery } from "@tanstack/react-query";
import { saveAs } from "file-saver";

import { showToast } from "../components/IstariToast/showToast";
import { COMMENTS_APPROVAL_LIST_SIZE } from "../constants";
import { UserGrantType } from "../enums";
import { fileService, s3Service } from "../services";
import { sanitizeData } from "../utils/sanitize";
import { stringifyObjectValue } from "../utils/strings";
import { modelApprovalQueryKeys } from "./queryKeys";

export interface ModelArtifact {
  artifact_extension: string;
  artifact_status: string;
  artifact_type: string;
  created_at?: string;
  creator_id: string;
  creator: {
    name: string | null;
    given_name: string | null;
    family_name: string | null;
    id: string;
    sub: string;
  };
  file_size_bytes: null | string | number;
  file_type: null | string;
  file_version: null | string | number;
  id: string;
  model_id: string;
  name: string;
  render_info: {
    extractable: boolean;
    previewable: boolean;
    supported_models: string[];
    type: string;
  };
  signed_upload_url: string | undefined | null;
  signed_download_url: string | undefined;
  storage_object_name: string;
  updated_at: string;
  version_uuid?: string;
}

export type ExtractedArtifactDiagramOutput = {
  id?: string;
  name: string;
  output: string;
  type?: string;
};

export interface ModelCreator {
  id: string;
  name: string;
  given_name?: string;
  family_name?: string;
  sub?: string;
}

export interface UserGrant {
  grant: UserGrantType;
  id: string;
  model_id: string;
  user: ModelCreator;
}

export interface Model {
  base_artifact: ModelArtifact;
  created_at: string;
  creator: ModelCreator;
  creator_id: string;
  id: string;
  model_type: string;
  name: string;
  processing_status: string;
  updated_at: string;
  user_grants: UserGrant[];
}

export interface ListOptions {
  size: number;
  page: number;
  order_by: string | null;
  model_type__not_in?: string;
  name__like?: string;
  processing_status__not_in?: string;
}

export interface ListOptionsExtended extends ListOptions {
  creator__sub__not_in?: string;
  creator__sub?: string;
  name__like?: string;
  model_type?: string;
}

interface UserToShare {
  user_id: string;
  grant?: string;
}

interface RenameModelPayload {
  modelId: string;
  name: string;
}

interface RemoveModelPayload {
  modelId: string;
}

interface ShareModelPayload {
  grants: UserToShare[];
  modelId: string | undefined;
  removedUserIds: string[];
}

export const modelQueryKeys = {
  all: ["files"],
  item: (id: string) => [...modelQueryKeys.all, id],
  list: (filter: ListOptions) => [...modelQueryKeys.all, "list", filter],
  tasks: (modelId: string) => [...modelQueryKeys.item(modelId), "tasks"],
};

export const artifactQueryKeys = {
  all: ["artifacts"],
  item: (id: string) => [...artifactQueryKeys.all, id],
};

export const useModels = (filter: ListOptions) => {
  const queryOptsString = new URLSearchParams(stringifyObjectValue(filter)).toString();

  return useQuery(modelQueryKeys.list(filter), () => fileService.get(`/model/?${queryOptsString}`), {
    refetchOnWindowFocus: false,
  });
};

export const useModel = (modelId: string, modelData?: Model) =>
  useQuery(modelQueryKeys.item(modelId), () => fileService.get(`/model/${modelId}`), {
    refetchOnWindowFocus: false,
    enabled: !modelData,
    retry: false,
  });

export const useRenameModel = () =>
  useMutation((data: RenameModelPayload) => fileService.patch(`/model/${data.modelId}`, { name: data.name }));

export const useRemoveModel = () =>
  useMutation((data: RemoveModelPayload) => fileService.remove(`/model/${data.modelId}`));

export const useShareModel = () =>
  useMutation((data: ShareModelPayload) =>
    fileService.patch(`/model/${data.modelId}/user_share/bulk`, {
      grant_updates: data.grants,
      user_ids_to_remove: data.removedUserIds,
    }),
  );

export async function downloadS3SignedUrlFileAsObj(url: string | null | undefined) {
  if (url) {
    const response = await s3Service.get(url, {
      withCredentials: false,
      transformResponse: [
        function (data: any) {
          return sanitizeData(data);
        },
      ],
    });

    if (response) {
      return new File([response], ".obj");
    }
  } else {
    showToast("An error occurred while downloading the 3D file.", "error");
  }
  return null;
}

export async function downloadS3SignedUrlFileAsString(url: string | null | undefined) {
  if (url) {
    const response = await s3Service.get(url, {
      withCredentials: false,
      responseType: "text",
      transformResponse: [
        function (data: any) {
          return sanitizeData(data);
        },
      ],
    });
    return response;
  }
  showToast("An error occurred while downloading the file.", "error");

  return null;
}

async function downloadSaveS3SignedUrlFile(url: string, fileName: string) {
  const response = await s3Service.get(url, { withCredentials: false, responseType: "blob" });

  if (response) {
    saveAs(new Blob([response], { type: response.type }), fileName);
    showToast(`${fileName} has been downloaded successfully.`, "success");
  } else {
    showToast("An error occurred while downloading the file.", "error");
  }
}

export async function downloadModel(fileName: string, modelId: string) {
  const refetchedModel = await fileService.get(`/model/${modelId}`);
  downloadSaveS3SignedUrlFile(refetchedModel.base_artifact.signed_download_url, fileName);
}

export async function downloadArtifact(fileName: string, modelId: string, artifactId: string) {
  const refetchedModelArtifact = await fileService.get(`/model/${modelId}/artifact/${artifactId}`);
  downloadSaveS3SignedUrlFile(refetchedModelArtifact.signed_download_url, fileName);
}

export const useGetModelLatestExtractionTask = (modelId: string) =>
  useQuery(modelQueryKeys.tasks(modelId), () => fileService.get(`/model/${modelId}/latest_extraction`), {
    refetchOnWindowFocus: false,
    retry: false,
  });

export const useExtractModel = (fileType: string, modelId: string) =>
  useMutation(() => fileService.post(`/model/${modelId}/function/${fileType}/${fileType}_extraction`));

export const useGetArtifactDataById = (modelId: string, artifactId: string) =>
  useQuery(artifactQueryKeys.item(artifactId), () => fileService.get(`/model/${modelId}/artifact/${artifactId}`), {
    refetchOnWindowFocus: false,
    enabled: !!modelId && !!artifactId,
  });

export const useGetModelApprovalUsers = (modelId: string, enabled: boolean) =>
  useQuery(
    modelApprovalQueryKeys.item(modelId),
    () => fileService.get(`/model/${modelId}/approval?size=${COMMENTS_APPROVAL_LIST_SIZE}`),
    {
      refetchOnWindowFocus: false,
      select: (data) => data.items,
      enabled,
    },
  );
