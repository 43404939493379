export type QueryKeys = {
  all: string[];
  item: (_id: string, ..._more: string[]) => Array<string>;
  list: (_filter: unknown) => Array<string | unknown>;
};

/**
 * Users query keys
 */
const usersQueryKeys: QueryKeys = {
  all: ["users"],
  item: (id) => [...usersQueryKeys.all, id],
  list: (filter) => [...usersQueryKeys.all, "list", filter],
};

/**
 * Magic Docs query keys
 */
const magicDocsQueryKeys: QueryKeys = {
  all: ["magic_docs"],
  item: (docId) => [...magicDocsQueryKeys.all, docId],
  list: (filter) => [...magicDocsQueryKeys.all, "list", filter],
};

const modelApprovalQueryKeys: QueryKeys = {
  all: ["model_approval"],
  item: (id) => [...modelApprovalQueryKeys.all, id],
  list: (filter) => [...modelApprovalQueryKeys.all, "list", filter],
};

export { usersQueryKeys, magicDocsQueryKeys, modelApprovalQueryKeys };
